






























































































/* eslint-disable camelcase */
import Vue from 'vue';
import Component from 'vue-class-component';
import { AxiosResponse } from 'axios';
import { Location } from '@/types/location-types';
import LocationService from '@/services/Location.service';

@Component
export default class UserList extends Vue {
  Modal = false;

  DisplayData = new Array<Record<string, string>>();

  Loading = true;

  Avatar = 0;

  LocationList = new Array<Location>();

  RequestLoading = false;

  async mounted() {
    const LService = new LocationService();

    try {
      this.Loading = true;
      const response: AxiosResponse = await LService.getAll();
      response.data.forEach((location: Location) => {
        this.LocationList.push(location);
      });
      this.Loading = false;
    } catch (error) {
      console.log(error);
    }
  }
}
