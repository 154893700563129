import HttpClient from '@/services/Api.service';
import { RawUser } from '@/types/user-types.interface';
import { AxiosPromise } from 'axios';

class UserService extends HttpClient {
  public constructor() {
    super((process.env.VUE_APP_BASE_URL)!);
  }

  public login = (params: RawUser) => this.instance.post('/login', params);

  /**
   * TODO:
   *  Consider adding a logout request, to inform the server of the
   *  session termination (in case Redis or another session registry
   *  will be used)
   */
  public getRole = (params?: RawUser): AxiosPromise => this.instance.get('/get-user-role', { params });

  public getAll = (): AxiosPromise => this.instance.get('/get-all-users');

  public getSingle = (params: Record<string, string>): AxiosPromise => this.instance.get('/get-user', { params });

  public makeSingle = (body: RawUser): AxiosPromise => this.instance.post('/create-user', body);

  public archiveSingle = (params?: RawUser): AxiosPromise => this.instance.delete('/archive-user', { params });
}

export default UserService;
