












































































































import 'reflect-metadata';
import { Vue, Component } from 'vue-property-decorator';

type ServiceFormType = 'checkbox' | 'text' | 'file' | 'finance';

@Component
export default class ServiceFormElement extends Vue {
  /* private */ internalName = '';

  /* private */ internalFinance = 0;

  /* private */ internalType: ServiceFormType = 'checkbox';

  /* private */ internalOptional = false;

  availableTypes: ServiceFormType[] = [
    'checkbox', 'text', 'file', 'finance',
  ];

  updateTaskType(newType: string): void {
    this.$emit('update-state', { type: newType });
  }

  updateTaskName(newName: string): void {
    this.$emit('update-state', { name: newName });
  }

  updateTaskFinance(newFinance: string): void {
    let newParsedFin: number;
    try {
      newParsedFin = parseFloat(newFinance);
    } catch {
      newParsedFin = 0.0;
    }
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(newParsedFin)) {
      newParsedFin = 0.0;
    }
    this.$emit('update-state', { finance: newParsedFin });
  }

  updateOptional(): void {
    this.internalOptional = !this.internalOptional;
    this.$emit('update-state', { optional: this.internalOptional });
  }

  ValidationRules: Record<string, Array<(v: string) => boolean | string >> = {
    RequiredRules: [
      (v: string): boolean | string => !!v || 'Field required!',
    ],
    FinanceRules: [
      (v: string): boolean | string => !!v || 'Field required!',
      (v: string): boolean | string => {
        console.log(v);
        return v !== '0' || 'Must not be 0!';
      },
    ],
    EmailRules: [
      (v: string): boolean | string => !!v || 'Last name required!',
      (v: string): boolean | string => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    PasswordRules: [
      (v: string): boolean | string => !!v || 'Password address required!',
      (v: string): boolean | string => v.length > 5 || 'Password must be at leas 5 characters!',
    ],
    PhoneRules: [
      (v: string): boolean | string => !!v || 'Phone number required!',
    ],
  };
}
