


























































/* eslint-disable import/no-cycle */
import 'reflect-metadata';
import { Vue, Component, Prop } from 'vue-property-decorator';
import PSCard from '@/components/application/PackagesAndServices/PSCard.vue';
import Loading from '@/components/application/Loading.vue';
import { Package } from '@/types/pst-types';
import PSTService from '@/services/PST.service';

@Component({
  components: {
    PSCard,
    Loading,
  },
})
export default class PackagePage extends Vue {
  LoadingState = true;

  Package: Package = new Package();

  public async goToService(id: number) {
    await this.$router.push({ name: 'View Service Template', params: { id: id.toString() } });
  }

  async mounted() {
    const PSTS = new PSTService();
    const responses: any = await Promise.all(
      [PSTS.getPackage({ id: parseInt(this.$route.params.id, 10) })],
    );
    // TODO: Add some error checking here
    this.Package!.fromRaw(responses[0].data);
    this.LoadingState = false;
  }
}
