/* eslint-disable camelcase */
import HttpClient from '@/services/Api.service';
import axios, { AxiosPromise, AxiosResponse } from 'axios';
import Store from '@/store';

interface Filter {
  field: string;
  operator: string;
  operand: string;
}

interface ModularCustomerParameters {
  id?: number;
  phone?: string;
  page?: number;
  search?: string;
  filters?: Filter[];
  order?: string;
  filtered_sum?: boolean | 1 | 0;
}

/* eslint-disable camelcase */
interface NoteCreationParameters {
  customer_id: number;
  note: string;
}

interface CustomerFilesGet {
  customer_id: number;
}

interface AttachDetailRequest {
  detail_id: number;
  customer_id: number;
  value: string;
}

interface ModifyDetailRequest {
  detail_id: number;
  value: string;
}

interface DeletePackageParameters {
  customer_id: number;
  package_id: number;
}

interface SMSParams {
  message: string;
  customer_id: number;
}

class CustomerService extends HttpClient {
  public constructor() {
    super((process.env.VUE_APP_BASE_URL)!);
  }

  public getSingle = (params: ModularCustomerParameters) => this.instance.get('/get-customer', { params });

  public makeSingle = (FD: FormData) => axios({
    method: 'post',
    url: `${process.env.VUE_APP_BASE_URL}/create-customer`,
    data: FD,
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      Authorization: `Bearer ${Store.state.User.Token}`,
    },
  });

  public editSingle = (patch: string, params: ModularCustomerParameters) => this.instance.patch('/edit-customer', patch, { params });

  public archiveSingle = (params: ModularCustomerParameters) => this.instance.delete('/archive-customer', { params });

  public searchAndPaginate = (params: ModularCustomerParameters) => this.instance.get('/paginate-customers', { params });

  public getBelongingDocuments = (params: ModularCustomerParameters) => this.instance.get('/get-customers-documents', { params });

  public getBelongingPackages = (params: ModularCustomerParameters) => this.instance.get('/get-customer-packages', { params });

  public assignPackage = (params: any) => this.instance.post('/add-package-to-customer', params);

  public getSingleBelongingPackage = (params: any) => this.instance.get('/get-customer-package', { params });

  public getJourney = (params: ModularCustomerParameters) => this.instance.get('/get-customer-journey', { params });

  public getNotes = (params: any) => this.instance.get('/get-notes', { params });

  public createNote = (params: NoteCreationParameters) => this.instance.post('/create-note', params);

  public getFiles = (params: CustomerFilesGet) => this.instance.get('/get-customer-directory', { params });

  public upgradeSingle = (FD: FormData) => axios({
    method: 'post',
    url: `${process.env.VUE_APP_BASE_URL}/upgrade-customer`,
    data: FD,
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      Authorization: `Bearer ${Store.state.User.Token}`,
    },
  });

  public getDetails = (params: {customer_id: number}) =>
    this.instance.get('/get-customer-details', { params });

  public getDetailTemplates = () =>
    this.instance.get('/get-detail-templates');

  public attachDetail = (params: AttachDetailRequest) =>
    this.instance.post('/attach-detail', params);

  public modifyDetail = (params: ModifyDetailRequest) =>
    this.instance.post('/modify-detail', params);

  public deletePackage = (params: DeletePackageParameters) =>
    this.instance.delete('/delete-empty-package', { params });

  public sendSms = (params: SMSParams) =>
    this.instance.post('/send-message', params);

  public checkAvailableDevices = (): AxiosPromise<{ active: boolean; }> =>
    this.instance.get('/check-available-devices');
}

export default CustomerService;
