/* eslint-disable camelcase */
import { AxiosPromise } from 'axios';
import jsonpatch from 'fast-json-patch';
import { Location } from '@/types/location-types';
import CustomerService from '@/services/Customer.service';
import { assignIfDefined } from '@/util/assign-if-defined.util';
import { stripFunctions } from '@/util/strip-functions.util';
import { MissingFieldsError } from '@/util/missing-fields-error.util';
import { CustomerIDocument } from '@/types/document-types';
import { RawCustomer } from './customer-types.interface';

interface Officer {
  name: string;
  email: string;
  permission_level: number;
}

export class Customer {
  public id?: number;

  public status = '';

  public first_name = '';

  public last_name = '';

  public email = '';

  public phone = '';

  public payment_done?: number;

  public payment_due?: number;

  public location: Location | undefined = undefined;

  public documents: CustomerIDocument[] = [];

  public user: Officer[] = [];

  public fromRaw = (rd: RawCustomer | any): void => {
    const assignIfAvailable = assignIfDefined.bind(this);
    assignIfAvailable<number>('id', rd.id);
    assignIfAvailable<string>('status', rd.status);
    assignIfAvailable<string>('first_name', rd.first_name);
    assignIfAvailable<string>('last_name', rd.last_name);
    assignIfAvailable<string>('email', rd.email);
    assignIfAvailable<string>('phone', rd.phone);
    assignIfAvailable<Location>('location', rd.location);
    assignIfAvailable<Officer>('user', rd.user);
    assignIfAvailable<CustomerIDocument[]>('location', rd.documents);
    assignIfAvailable<string>('created_at', rd.created_at);
    assignIfAvailable<number>('payment_done', rd.payment_done);
    assignIfAvailable<number>('payment_due', rd.payment_due);
  }

  /**
   * @method toRaw
   * Used for API interop. This method uses loose semantics in order
   * to have a cherry-pick system for the end user, which formats the
   * returned data to JSON and ships it with a request to the API.
   */
  toRaw(props: Array<keyof RawCustomer>): RawCustomer {
    const constructedRawCustomer: {[K in keyof RawCustomer]: any} = {};
    props.forEach((key) => {
      constructedRawCustomer[key] = this[key];
    });
    return constructedRawCustomer;
  }

  archive(): AxiosPromise {
    if (!this.id) {
      throw new MissingFieldsError('Some or all required fields for user creation are not set.');
    }

    const CService = new CustomerService();

    const rawUser: RawCustomer = this.toRaw([
      'id',
    ]);

    return CService.archiveSingle(rawUser);
  }

  public edit = async (changes: Customer, id?: number) => {
    const patch = jsonpatch.compare(
      stripFunctions(this),
      stripFunctions(changes),
    );

    const CService = new CustomerService();

    const _id = this.id ?? id;

    if (_id) {
      return CService.editSingle(JSON.stringify(patch), { id: _id });
    }
    throw Error('No ID provided for this operation.');
  }

  // save(d?: CustomerIDocument[]): AxiosPromise {
  //   if (!(this.status
  //      && this.first_name
  //      && this.last_name
  //      && this.email
  //      && this.phone)) {
  //     throw new MissingFieldsError('Some or all required fields for user creation are not set.');
  //   }
  //
  //   const CService = new CustomerService();
  //   if (typeof d !== 'undefined') {
  //     return CService.makeSingle(this, d);
  //   }
  //   return CService.makeSingle(this);
  // }
}

export class CustomerBuilder {
  private _customer: Customer;

  get customer(): Customer {
    return this._customer;
  }

  public Status(t: 'lead' | 'customer'): CustomerBuilder {
    this._customer.status = t;
    return this;
  }

  public FirstName(fn: string): CustomerBuilder {
    this._customer.first_name = fn;
    return this;
  }

  public LastName(ln: string): CustomerBuilder {
    this._customer.last_name = ln;
    return this;
  }

  public Email(e: string): CustomerBuilder {
    this._customer.email = e;
    return this;
  }

  public Phone(p: string): CustomerBuilder {
    this._customer.phone = p;
    return this;
  }

  public constructor(c: Customer) {
    this._customer = c;
  }
}
