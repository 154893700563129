










































































































/* eslint-disable camelcase */
import 'reflect-metadata';
// eslint-disable-next-line object-curly-newline
import { Vue, Component, Prop } from 'vue-property-decorator';
import { CustomerIDocument, IDocumentType } from '@/types/document-types';
import DocumentService from '@/services/Document.service';

type VuetifyDoctypeSelect<T> = {
  [P in keyof T]: T[P];
} & {
  text: string;
}

@Component
export default class DocumentUpdateDialog extends Vue {
  @Prop() readonly model!: boolean;

  @Prop() readonly customerId!: number;

  @Prop() readonly typeId!: number;

  @Prop() readonly doctypes!: Array<VuetifyDoctypeSelect<IDocumentType>>;

  public guardedClose(): void {
    if (!this.loading) {
      this.$emit('click:outside');
    }
  }

  public LocalDocument: CustomerIDocument = new CustomerIDocument();

  public loading = false;

  public selectedUser: {id: number; name: string;} = {
    id: -1,
    name: 'Choose an Officer',
  }

  async updateDocument() {
    const ds = new DocumentService();

    this.loading = true;
    const reqJSON = {
      type_id: this.typeId === -1 ? this.LocalDocument.type_id : this.typeId,
      customer_id: this.customerId,
    };

    const FD = new FormData();

    FD.append('uploaded_file', this.LocalDocument.file);

    FD.append('json_data', JSON.stringify(reqJSON));

    await ds.updateDocument(FD);
    this.loading = false;
    this.$emit('done');
    this.guardedClose();
  }

  get conditions(): boolean {
    return (this.LocalDocument.file !== ''
      && (this.typeId !== -1 || parseInt(this.LocalDocument.type_id, 10) >= 0)
      && this.customerId !== -1);
  }

  mounted() {
    this.LocalDocument.type_id = '-1';
  }
}
