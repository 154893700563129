






























































import Vue from 'vue';
import { Component, Emit } from 'vue-property-decorator';

@Component
export default class Filters extends Vue {
  public Orders: Array<Record<string, string>> = [
    {
      name: 'Ascending',
      value: 'ascending',
    },
    {
      name: 'Descending',
      value: '',
    },
  ];

  public Order = '';

  public CustomerTypes: Array<Record<string, string>> = [
    {
      name: 'All',
      value: '',
    },
    {
      name: 'Customer',
      value: 'only_customers',
    },
    {
      name: 'Lead',
      value: 'only_leads',
    },
  ];

  public CustomerType = '';

  @Emit('change')
  computeFilters() {
    const filters: string[] = [];
    if (this.Order) filters.push(this.Order);
    if (this.CustomerType) filters.push(this.CustomerType);
    return filters;
  }
}
