/* eslint-disable global-require, import/extensions, import/no-unresolved */
import Vue from 'vue';
import VueI18n from 'vue-i18n';

import en from 'vuetify/src/locale/en';

import { VuetifyLocale } from 'vuetify/types/services/lang';

Vue.use(VueI18n);

interface Language {
  [key: string]: string | Record <string, VuetifyLocale>;
  $vuetify: Record <string, VuetifyLocale>;
}

interface Languages {
  [key: string]: Language;
}

const Messages: Languages & VueI18n.LocaleMessages = {
  en: {
    ...require('@/locales/en.json'),
    $vuetify: en,
  },
};

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: Messages,
});
