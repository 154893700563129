






















































































































































































import Component from 'vue-class-component';
import Vue from 'vue';
import jsonpatch from 'fast-json-patch';
import parsePhoneNumber, { CountryCode, PhoneNumber } from 'libphonenumber-js';
import CustomerService from '@/services/Customer.service';
import { Customer, CustomerBuilder } from '@/types/customer-types';

@Component
export default class EditLead extends Vue {
  ID!: number;

  OriginalLead = new Customer();

  LocalLead = new Customer();

  Builder = new CustomerBuilder(this.LocalLead);

  RequestLoading = false;

  FormValid = false;

  PhoneCountry: CountryCode = 'GB';

  PhoneCountries: CountryCode[] = this.$store.state.General.phoneCountries;

  LocalPhoneNumber(): PhoneNumber | undefined {
    return parsePhoneNumber(
      this.LocalLead.phone,
      this.PhoneCountry,
    );
  }

  ValidationRules: Record<string, Array<(v: string) => boolean | string>> = {
    FirstNameRules: [
      (v: string): boolean | string => !!v || 'First name required!',
    ],
    LastNameRules: [
      (v: string): boolean | string => !!v || 'Last name required!',
    ],
    EmailRules: [
      // (v: string): boolean | string => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    PhoneRules: [
    ],
  };

  mounted() {
    const leadData: Customer = JSON.parse(decodeURIComponent(this.$route.params.data));
    this.Builder.Status('lead')
      .FirstName(leadData.first_name)
      .LastName(leadData.last_name)
      .Email(leadData.email)
      .Phone(leadData.phone);
    this.OriginalLead.fromRaw(this.LocalLead.toRaw([
      'status',
      'first_name',
      'last_name',
      'email',
      'phone',
      'location',
    ]));
    this.ID = leadData.id!;
  }

  FormConditions = {
    UniqueEmail: true,
    UniquePhone: true,
    AtLeastOne: false,
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  OneTimeWatchers: Record<string, Function> = {
    EmailUnwatch: () => null,
    PhoneUnwatch: () => null,
  };

  async editLead() {
    const cs = new CustomerService();

    const patch = jsonpatch.compare(this.OriginalLead, this.LocalLead);

    try {
      this.RequestLoading = true;
      const ProcessedLead = this.LocalLead;
      if (this.LocalLead.phone) {
        ProcessedLead.phone = this.LocalPhoneNumber()!.number as string;
      }
      await cs.editSingle(JSON.stringify(patch), { id: this.ID });
      this.RequestLoading = false;
      /* TODO: Either pass additional params or signal on
               this page that the creation was successful */
      this.$router.push({ name: 'Customer Index' });
    } catch (error) {
      this.RequestLoading = false;
      this.FormValid = false;

      /* TODO: This inconsistency is caused by lack of back-end standardization
              Unify error formats. */

      if (error.response.data.data.email_duplicate) {
        this.FormConditions.UniqueEmail = false;
        this.OneTimeWatchers.EmailUnwatch = this.$watch(
          () => this.LocalLead.email,
          () => {
            this.FormConditions.UniqueEmail = true;
            this.OneTimeWatchers.EmailUnwatch();
          },
        );
      }

      if (error.response.data.data.phone_duplicate) {
        this.FormConditions.UniquePhone = false;
        this.OneTimeWatchers.PhoneUnwatch = this.$watch(
          () => this.LocalLead.phone,
          () => {
            this.FormConditions.UniquePhone = true;
            this.OneTimeWatchers.PhoneUnwatch();
          },
        );
      }
    }
  }

  get AtLeastOne() {
    if (!!this.LocalLead.phone || !!this.LocalLead.email) return [];
    return 'At least one of these fields must be filled';
  }

  get UniqueEmailError() {
    if (this.FormConditions.UniqueEmail
      && (!!this.LocalLead.phone || !!this.LocalLead.email)
      && (/.+@.+\..+/.test(this.LocalLead.email) || !this.LocalLead.email)) {
      return [];
    }
    if (!this.FormConditions.UniqueEmail) {
      return 'Email must be unique!';
    }
    if (!/.+@.+\..+/.test(this.LocalLead.email) && !!this.LocalLead.email) {
      return 'The email is not valid';
    }
    return 'At least one of these fields must be filled';
  }

  get UniquePhoneError() {
    let PN: boolean = (this.LocalLead.phone && this.LocalLead.phone !== '0')
      ? this.LocalPhoneNumber()!.isValid() : false;
    if (!this.LocalLead.phone) {
      PN = true;
    }
    if (PN
      && this.FormConditions.UniquePhone
      && (!!this.LocalLead.phone || !!this.LocalLead.email)) {
      return [];
    }
    if (!this.FormConditions.UniquePhone) {
      return 'Phone must be unique!';
    }
    if (!this.LocalLead.phone && !this.LocalLead.email) {
      return 'At least one of these fields must be filled';
    }
    return 'The given phone number is invalid';
  }
}
