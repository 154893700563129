/* eslint-disable import/prefer-default-export */
/**
 * @function stripFunctions
 * Utility method, for stripping methods from objects. This will also
 * strip the class, although later constructor calls may restore it.
 */
export function stripFunctions<T>(toStrip: T) {
  if (toStrip) {
    return JSON.parse(JSON.stringify(toStrip));
  }
  return {};
}
