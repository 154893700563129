










































































































import 'reflect-metadata';
import { Vue, Component, Prop } from 'vue-property-decorator';
import FileService from '@/services/File.service';
import { IDocumentType } from '@/types/document-types';

@Component
export default class CustomerDocument extends Vue {
  @Prop() readonly typeid!: number;

  @Prop() readonly code!: string;

  @Prop() readonly filepath!: string;

  @Prop() readonly doctypes!: IDocumentType[];

  public loadingButton = false;

  // eslint-disable-next-line class-methods-use-this
  public async downloadFile() {
    /* TODO: Could have a race condition here, make sure this is callable
    only after */
    if (this.filepath === '' || typeof this.filepath === 'undefined') {
      return;
    }
    this.loadingButton = true;
    await FileService.AccessFile(this.filepath, window, document);
    this.loadingButton = false;
  }

  public async viewFile(): Promise<void> {
    this.loadingButton = true;
    await FileService.AccessFile(this.filepath, window, document, 'view');
    this.loadingButton = false;
  }

  get typename(): string {
    const result = this.doctypes.find((obj) => obj.id === this.typeid);
    if (typeof result === 'undefined') return 'Doctype Loading';
    return result.name;
  }
}
