































































































































































import { Vue, Component } from 'vue-property-decorator';
import CustomerService from '@/services/Customer.service';
import DocumentService from '@/services/Document.service';
import { AxiosResponse } from 'axios';
import { CustomerIDocument, IDocumentType } from '@/types/document-types';
import { Package } from '@/types/pst-types';
import { Customer } from '@/types/customer-types';
import { BarAction } from '@/components/application/UtilityBar/utility-bar-types';
import AddDetailModal from '@/components/application/Customer/AddDetailModal.vue';
import UtilityBar from '@/components/application/UtilityBar/UtilityBar.vue';
import ModifyDetailDialog from '@/components/application/Customer/ModifyDetailDialog.vue';

@Component({
  components: {
    AddDetailModal,
    UtilityBar,
    ModifyDetailDialog,
  },
})
export default class CustomerDetails extends Vue {
  public barChunks: BarAction[] = [
    {
      icon: 'mdi-arrow-left',
      text: 'BACK',
      eventName: 'back',
    },
    {
      icon: 'mdi-text-box-plus',
      text: 'ADD DETAIL',
      eventName: 'add-detail',
    },
  ];

  Packages: Package[] = [];

  CustomerPackages: Package[] = [];

  Loading = true;

  CustomerData = new Customer();

  DisplayData = new Array<Record<string, string | boolean>>();

  DocumentTypes = new Array<IDocumentType>();

  Documents = new Array<CustomerIDocument>();

  customerDetails = new Array<{
      id: number,
      name: string,
    }>();

  modifyDetailModal = false;

  detailId = -1;

  detailName = '';

  detailValue = '';

  openModifyDetailDialog(id: number, name: string, value: string) {
    this.detailId = id;
    this.detailName = name;
    this.detailValue = value;
    this.modifyDetailModal = true;
  }

  async mounted() {
    this.Packages = [];

    const CService = new CustomerService();
    const DService = new DocumentService();
    let documentResponse: AxiosResponse;
    try {
      this.Loading = true;
      const singleCustomer = CService.getSingle({ id: parseInt(this.$route.params.id, 10) });

      const customerResponse = await singleCustomer;
      this.CustomerData.fromRaw(customerResponse!.data);

      const CUSTOMER_REQUESTS = [
        DService.getAllTypes(),
        CService.getDetails({
          customer_id: parseInt(this.$route.params.id, 10),
        }),
        CService.getDetailTemplates(),
      ];

      const LEAD_REQUESTS = [
        DService.getAllTypes(),
        CService.getDetails({
          customer_id: parseInt(this.$route.params.id, 10),
        }),
        CService.getDetailTemplates(),
      ];

      const responses = await Promise.all(
        this.CustomerData.status.toLowerCase() === 'customer'
          ? CUSTOMER_REQUESTS : LEAD_REQUESTS,
      );

      const [doctypeResponse, detailResponse, detailTemplateResponse] = responses;

      documentResponse = await CService.getBelongingDocuments(
        { id: parseInt(this.$route.params.id, 10) },
      );

      documentResponse.data.forEach((document: any) => {
        const tDocument = new CustomerIDocument();
        tDocument.fromRaw(document);
        this.Documents.push(tDocument);
      });

      this.DisplayData.push(
        {
          name: 'STATUS',
          data: this.CustomerData.status.toUpperCase(),
        },
        {
          name: 'FIRST NAME',
          data: this.CustomerData.first_name,
        },
        {
          name: 'LAST NAME',
          data: this.CustomerData.last_name,
        },
        {
          name: 'EMAIL ADDRESS',
          data: this.CustomerData.email,
        },
        {
          name: 'PHONE NUMBER',
          data: this.CustomerData.phone,
        },
        {
          name: 'LOCATION',
          data: this.CustomerData.location!.name,
        },
      );

      detailTemplateResponse.data.forEach((detail: any) => {
        this.customerDetails.push(detail);
      });

      detailResponse.data.forEach((detail: any) => {
        this.DisplayData.push({
          id: detail.id,
          custom: true,
          name: detail.name || 'N/A',
          data: detail.value,
        });
      });

      await doctypeResponse!.data.forEach((doctype: any) => {
        this.DocumentTypes.push(new IDocumentType(doctype.id, doctype.name, doctype.description));
      });

      this.Loading = false;
    } catch (error) {
      console.log(error);
    }
  }

  async refreshData() {
    this.Loading = true;
    const CService = new CustomerService();
    const response = await CService.getDetails({
      customer_id: parseInt(this.$route.params.id, 10),
    });
    this.DisplayData = [];
    this.DisplayData.push(
      {
        name: 'STATUS',
        data: this.CustomerData.status.toUpperCase(),
      },
      {
        name: 'FIRST NAME',
        data: this.CustomerData.first_name,
      },
      {
        name: 'LAST NAME',
        data: this.CustomerData.last_name,
      },
      {
        name: 'EMAIL ADDRESS',
        data: this.CustomerData.email,
      },
      {
        name: 'PHONE NUMBER',
        data: this.CustomerData.phone,
      },
      {
        name: 'LOCATION',
        data: this.CustomerData.location!.name,
      },
    );
    response.data.forEach((detail: any) => {
      console.log(detail);
      this.DisplayData.push({
        id: detail.id,
        custom: true,
        name: detail.name || 'N/A',
        data: detail.value,
      });
    });
    this.Loading = false;
  }

  public addDetailModal = false;

  public openAddDetail(): void {
    this.addDetailModal = true;
  }

  public closeAddDetail(): void {
    this.addDetailModal = false;
  }
}
