














































































































































import 'reflect-metadata';
// eslint-disable-next-line object-curly-newline
import { Vue, Component, Prop } from 'vue-property-decorator';
import CustomerService from '@/services/Customer.service';
import { AxiosResponse } from 'axios';

/* eslint-disable camelcase */
export interface Note {
  id: number,
  customer_id: number,
  note: string,
  created_at: string,
  updated_at: string,
  user_name: string,
}
/* eslint-enable camelcase */

@Component
export default class NotesDialog extends Vue {
  @Prop() readonly model!: boolean;

  @Prop() readonly notes!: Note[];

  @Prop() readonly customerId!: number;

  public guardedClose(): void {
    if (!this.loading) {
      this.$emit('click:outside');
    }
  }

  public async createNote(): Promise<any> {
    this.sendLoading = true;
    const C = new CustomerService();
    try {
      if (this.expandText) {
        await C.createNote({
          customer_id: parseInt(this.$route.params.id, 10),
          note: this.noteText,
        });
        this.$emit('request-update');
        this.noteText = '';
        this.expandText = false;
      } else {
        // eslint-disable-next-line no-alert
        if (!window.confirm(
          `This action will send an SMS to the customer. Proceed?\nMessage: ${this.smsText}`,
        )) {
          this.sendLoading = false;
          return;
        }
        await C.sendSms({
          customer_id: parseInt(this.$route.params.id, 10),
          message: this.smsText,
        });
        this.$emit('request-update');
        this.smsText = '';
        this.expandSms = false;
      }
    } catch (e) {
      console.log(e);
    }
    this.sendLoading = false;
  }

  public toggleSms(value: boolean) {
    if (value) {
      this.expandText = false;
    }
    this.expandSms = value;
  }

  public toggleText(value: boolean) {
    if (value) {
      this.expandSms = false;
    }
    this.expandText = value;
  }

  get submitAvailable() {
    if (this.expandSms && this.smsText !== '' && this.devicesAvailable) return false;
    return !(this.expandText && this.noteText !== '');
  }

  public loading = false;

  public sendLoading = false;

  public expandText = false;

  public expandSms = false;

  public noteText = '';

  public smsText = '';

  // eslint-disable-next-line class-methods-use-this
  public noteDateFormat(d: Date): string {
    const hours = `${d.getHours() < 10 ? '0' : ''}${d.getHours()}`;
    const minutes = `${d.getMinutes() < 10 ? '0' : ''}${d.getMinutes()}`;
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()} ${hours}:${minutes}`;
  }

  devicesAvailable = false;

  async mounted() {
    const C = new CustomerService();
    const response = await C.checkAvailableDevices();
    this.devicesAvailable = response.data.active;
  }
}
