









































/* eslint-disable object-curly-newline */
import 'reflect-metadata';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class InfoDisplayBox extends Vue {
  @Prop({
    default: [],
  }) readonly info!: Array<{
    field: string;
    value: string | number;
  }>;

  @Prop({
    default: '',
  }) readonly hardFilter!: string;

  get processedInfo(): Array<{
    field: string;
    value: string | number;
    hardFilter?: string;
  }> {
    return this.info.filter((i) => typeof i.value !== 'undefined');
  }
}
