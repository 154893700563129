








































































import 'reflect-metadata';
// eslint-disable-next-line object-curly-newline
import { Component, Prop, Vue } from 'vue-property-decorator';
import CustomerService from '@/services/Customer.service';
import FileService from '@/services/File.service';

@Component
export default class CustomerFilesDialog extends Vue {
  @Prop() readonly model!: boolean;

  @Prop() readonly files!: string[];

  public guardedClose(): void {
    if (!this.loading) {
      this.$emit('click:outside');
    }
  }

  get filenames(): string[] {
    return this.files.map((pathname: string) =>
      pathname.split('/').pop()!.split('-').splice(1).join('-'));
  }

  public loading = false;

  public async viewFile(index: number): Promise<void> {
    this.loading = true;
    await FileService.AccessFile(this.files[index], window, document, 'view');
    this.loading = false;
  }

  public async downloadFile(index: number): Promise<void> {
    this.loading = true;
    await FileService.AccessFile(this.files[index], window, document);
    this.loading = false;
  }
}
