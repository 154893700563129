



























































































































































/* eslint-disable prefer-const */
import Vue from 'vue';
import Component from 'vue-class-component';
import CustomerService from '@/services/Customer.service';
import { Customer } from '@/types/customer-types';
import { AxiosResponse } from 'axios';
import Filters from '@/components/application/CustomerIndex/Filters.vue';
import { BarAction } from '@/components/application/UtilityBar/utility-bar-types';
import UtilityBar from '@/components/application/UtilityBar/UtilityBar.vue';

@Component({
  components: {
    Filters,
    UtilityBar,
  },
})
export default class CustomerIndex extends Vue {
  public barChunks: BarAction[] = [
    {
      icon: 'mdi-account-plus',
      text: 'ADD CUSTOMER',
      eventName: 'new-customer',
    },
  ];

  public goToNewCustomer(): void {
    this.$router.push({ name: 'New Customer' });
  }

  SearchField = '';

  TotalPages = 0;

  LoadingResults = false;

  CurrentPage = 0;

  TableData: Customer[] = [];

  Filters: string[] = []

  TotalDue = 0;

  async searchAndPaginate(e: string) {
    const CService = new CustomerService();
    let response: AxiosResponse;
    this.TableData = [];
    try {
      this.LoadingResults = true;
      response = await CService.searchAndPaginate({ search: e });
      this.LoadingResults = false;
      const CustomerData = response.data.data;
      this.TotalPages = response.data.last_page;
      this.CurrentPage = 1;
      CustomerData.forEach((customer: any) => {
        const tCustomer = new Customer();
        tCustomer.fromRaw(customer);
        this.TableData.push(tCustomer);
      });
    } catch (error) {
      console.log(error);
    }
  }

  updateFilters(f: string[]) {
    this.Filters = f;
    this.CurrentPage = 0;
    this.searchAndPaginate(this.SearchField);
  }

  async moveToPage(e: string, f?: string[]) {
    const CService = new CustomerService();
    let response: AxiosResponse;
    this.TableData = [];
    try {
      this.LoadingResults = true;
      response = await CService.searchAndPaginate(
        { search: this.SearchField, page: parseInt(e, 10) },
      );
      const CustomerData = response.data.data;
      this.CurrentPage = parseInt(e, 10);
      CustomerData.forEach((customer: any) => {
        const tCustomer = new Customer();
        tCustomer.fromRaw(customer);
        this.TableData.push(tCustomer);
      });
      this.LoadingResults = false;
    } catch (error) {
      console.log(error);
    }
  }

  async goToProfile(c: Customer) {
    await this.$router.push({ name: 'Customer Profile', params: { id: c!.id!.toString() } });
  }

  mounted() {
    this.searchAndPaginate('');
  }
}

