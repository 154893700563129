



























































import 'reflect-metadata';
// eslint-disable-next-line object-curly-newline
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import CustomerService from '@/services/Customer.service';

@Component
export default class ModifyDetailDialog extends Vue {
  @Prop() readonly model!: boolean;

  @Prop() readonly detailId!: number;

  @Prop() readonly detailName!: string;

  @Prop() readonly detailValue!: string;

  public guardedClose(withRefresh = false): void {
    if (!this.loading) {
      if (withRefresh) {
        this.$emit('refresh');
      }
      this.$emit('click:outside');
    }
  }

  public loading = false;

  public detailValueState = '';

  async modifyDetail() {
    const CS = new CustomerService();
    await CS.modifyDetail({
      detail_id: this.detailId,
      value: this.detailValueState,
    });
    this.guardedClose(true);
  }

  @Watch('detailValue')
  public onDetailValueChange(newValue: string) {
    this.detailValueState = newValue;
  }
}
