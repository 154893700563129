






















































/* eslint-disable object-curly-newline */
import 'reflect-metadata';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class StringSelect extends Vue {
  @Prop({
    default: 'N/A',
  }) readonly field!: string;

  @Prop({
    default: '',
  }) readonly name!: string;

  @Prop({
    default: ['Please', 'Insert', 'Options'],
  }) readonly variants!: string[] | any[];

  @Prop({
    default: '',
  }) readonly variantsName!: string;

  @Prop({
    default: '',
  }) readonly variantsValue!: string;

  public dataModel = -1;

  get value(): string | undefined {
    const dataModelCheck: boolean = this.dataModel === -1 || typeof this.dataModel === 'undefined';
    if (this.variantsName !== '' && this.variantsValue !== '') {
      return dataModelCheck ? undefined : this.variants[this.dataModel][this.variantsValue];
    }
    return dataModelCheck ? undefined : this.variants[this.dataModel];
  }

  @Watch('dataModel')
  onModelChanged(newVal: number | undefined): void {
    console.log(newVal);
    if (newVal === -1 || typeof newVal === 'undefined') {
      this.$emit('unset-filters', [this.field]);
    } else if (typeof this.value !== 'undefined') {
      this.$emit('set-filters', [{
        field: this.field,
        operator: 'equal',
        operand: this.value,
        display: typeof this.variants[parseInt(this.value, 10) - 1] === 'undefined'
          ? 'N\\A' : this.variants[parseInt(this.value, 10) - 1].name,
      }]);
    }
  }
}
