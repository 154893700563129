




















































































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import SessionService from '@/services/Session.service';
import Loading from '@/components/application/Loading.vue';
import groupBy from '@/util/group-by';
import { Customer } from '@/types/customer-types';
import CustomerService from '@/services/Customer.service';
import { AxiosResponse } from 'axios';
import DateIntSelect from '@/components/application/Filters/DateIntSelect.vue';
import StringSearch from '@/components/application/Filters/StringSearch.vue';
import StringSelect from '@/components/application/Filters/StringSelect.vue';
import DisplayBox from '@/components/application/Filters/DisplayBox.vue';
import AddToSessionDialog from '@/components/application/Sessions/AddToSessionDialog.vue';
import LocationService from '@/services/Location.service';
import { Location } from '@/types/location-types';
import MassDownloadDialog from '@/components/application/Sessions/MassDownloadDialog.vue';
import RemoveCustomerDialog from '@/components/application/Sessions/RemoveCustomerDialog.vue';

@Component({
  components: {
    MassDownloadDialog,
    Loading,
    DateIntSelect,
    StringSearch,
    StringSelect,
    DisplayBox,
    AddToSessionDialog,
    RemoveCustomerDialog,
  },
})
export default class SingleSession extends Vue {
  LoadingState = true;

  Customers: any[] = [];

  MyCustomers: any[] = [];

  ShowMyCustomers = true;

  // CategoryCustomers: any[] = [];

  TableData: Customer[] = [];

  SortOrder = 'descending';

  CurrentPage = 0;

  TotalPages = 1;

  SessionId = -1;

  massDownloadDialog = false;

  removeCustomerDialog = false;

  rcCustomerId = -1;

  rcSthId = -1;

  sthId = -1;

  date = new Date();

  toggleMassDownloadDialog(customers: any) {
    /* eslint-disable camelcase */
    this.sthId = customers[0].sth_id;
    this.date = new Date(this.$route.params.date);
    this.massDownloadDialog = true;
    /* eslint-enable camelcase */
  }

  toggleRemoveCustomerDialog(customer: any) {
    /* eslint-disable camelcase */
    this.rcSthId = customer.sth_id;
    this.rcCustomerId = customer.id;
    this.removeCustomerDialog = true;
    /* eslint-enable camelcase */
  }

  addToSessionDialog = false;

  sdDate = new Date(this.$route.params.date);

  sdCustomerId = -1;

  Filters: Array<{
    field: string;
    operator: string;
    operand: string;
  }> = [];

  public questionCustomerForSession(customerId: number) {
    this.sdCustomerId = customerId;
    this.addToSessionDialog = true;
  }

  public async setFilters(filters: Array<{
    field: string;
    operator: string;
    operand: string;
  }>, requestless = false) {
    filters.forEach((filter) => {
      this.Filters = this.Filters.filter((e) => e.field !== filter.field);
    });
    this.Filters = this.Filters.concat(filters);
    if (!requestless) {
      await this.searchAndPaginate('');
    }
  }

  public async unsetFilters(filters: string[], requestless = false) {
    filters.forEach((filter) => {
      this.Filters = this.Filters.filter((e) => e.field !== filter);
    });
    if (!requestless) {
      await this.searchAndPaginate('');
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public parseDate(input: string): string {
    const d = new Date(input);
    return `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`;
  }

  async moveToPage(e: string, f?: string[]) {
    const CService = new CustomerService();
    let response: AxiosResponse;
    this.TableData = [];
    try {
      response = await CService.searchAndPaginate(
        {
          search: '',
          page: parseInt(e, 10),
          filters: this.Filters,
          order: this.SortOrder,
        },
      );
      const CustomerData = response.data.data;
      this.CurrentPage = parseInt(e, 10);
      CustomerData.forEach((customer: any) => {
        const tCustomer = new Customer();
        tCustomer.fromRaw(customer);
        this.TableData.push(tCustomer);
      });
    } catch (error) {
      console.log(error);
    }
  }

  async searchAndPaginate(e: string) {
    const CService = new CustomerService();
    let response: AxiosResponse;
    this.TableData = [];
    try {
      response = await CService.searchAndPaginate({
        search: e,
        filters: this.Filters,
        order: this.SortOrder,
      });
      const CustomerData = response.data.data;
      this.TotalPages = response.data.last_page;
      this.CurrentPage = 1;
      CustomerData.forEach((customer: any) => {
        const tCustomer = new Customer();
        tCustomer.fromRaw(customer);
        this.TableData.push(tCustomer);
      });
    } catch (error) {
      console.log(error);
    }
  }

  LocationVariants: string[] = [];

  async mounted() {
    const SS = new SessionService();
    const LS = new LocationService();
    const date = this.$route.params.date.split('-');
    const responses = await Promise.all([
      LS.getAll(),
      SS.getSingleSession({
        day: parseInt(date[2], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[0], 10),
      }),
    ]);

    this.LocationVariants = responses[0].data.map((location: Location) => location.name);

    const { customers } = responses[1].data;
    this.SessionId = responses[1].data.id;
    responses[1].data.pivot.forEach((pivotEntry: any) => {
      const relatedCustomer = customers.find((c: any) => c.id === pivotEntry.customer_id);
      const tempCustomer = { ...pivotEntry, ...relatedCustomer };

      if (this.$store.state.User.ID === tempCustomer.author) {
        this.MyCustomers.push(tempCustomer);
      }

      this.Customers.push(tempCustomer);
    });

    if (this.MyCustomers.length === 0) this.ShowMyCustomers = false;

    await this.searchAndPaginate('');

    this.LoadingState = false;
  }

  get CategoryCustomers() {
    return groupBy('service_name')(this.Customers);
  }

  get MyCategoryCustomers() {
    return groupBy('service_name')(this.MyCustomers);
  }
}
