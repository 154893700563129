/* eslint-disable import/prefer-default-export */
/* eslint-disable camelcase */
import { assignIfDefined } from '@/util/assign-if-defined.util';
import { RawUser } from '@/types/user-types.interface';

export class IDocumentType {
  public id = 0;

  public name = '';

  public description = '';

  public constructor(i?: number, n?: string, d?: string) {
    const assignIfAvailable = assignIfDefined.bind(this);
    assignIfAvailable('id', i);
    assignIfAvailable('name', n);
    assignIfAvailable('description', d);
  }
}

interface RawCustomerIDocument {
  type_id?: string;
  code?: string;
  status?: 'valid' | 'archived';
  customer_id?: string;
  id?: number;
}

export class CustomerIDocument {
  public type_id = '';

  public code = '';

  public status: 'valid' | 'archived' = 'valid';

  public customer_id?: string = '';

  public id?: number;

  public file = '';

  public constructor(tid?: string, c?: string, f?: string) {
    const assignIfAvailable = assignIfDefined.bind(this);
    assignIfAvailable.bind(this);
    assignIfAvailable('type_id', tid);
    assignIfAvailable('code', c);
    assignIfAvailable('file', f);
  }

  public toRaw(props: Array<keyof RawCustomerIDocument>): RawCustomerIDocument {
    const constructedRawCID: {[K in keyof RawCustomerIDocument]: any} = {};
    props.forEach((key) => {
      constructedRawCID[key] = this[key];
    });
    return constructedRawCID;
  }

  public fromRaw(rawData: any): void {
    const assignIfAvailable = assignIfDefined.bind(this);
    assignIfAvailable('id', rawData.id);
    assignIfAvailable('customer_id', rawData.customer_id);
    assignIfAvailable('type_id', rawData.type_id);
    assignIfAvailable('code', rawData.code);
    assignIfAvailable('status', rawData.status);
    assignIfAvailable('file', rawData.file);
  }
}
