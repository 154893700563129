





























































import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class CreateMenu extends Vue {
  Buttons: Array<Record<string, string | Array<string>>> = [
    {
      name: 'Customer',
      icon: 'mdi-text-box-plus',
      permissions: ['create-customer'],
      to: '/application/new-customer',
    },
    {
      name: 'Lead',
      icon: 'mdi-text-box-plus-outline',
      permissions: ['create-customer'],
      to: '/application/new-lead',
    },
    {
      name: 'Location',
      icon: 'mdi-map-marker-plus',
      permissions: ['create-location'],
      to: '/application/new-location',
    },
    {
      name: 'Employee',
      icon: 'mdi-account-plus',
      permissions: ['create-user'],
      to: '/application/new-user',
    },
  ];

  get ComputedButtons() {
    const ExistingPermissions = this.$store.state.User.Permissions;
    const FilteredButtons:
    Array<Record<string, string | Array<string>>> = this.Buttons.filter((button) => {
      const RequiredPermissions: Array<string> = button.permissions as Array<string>;
      return RequiredPermissions.every((perm) => ExistingPermissions.includes(perm));
    });
    return FilteredButtons;
  }
}

