





































































import 'reflect-metadata';
// eslint-disable-next-line object-curly-newline
import { Vue, Component, Prop } from 'vue-property-decorator';
import { PAYMENT, TRADER } from '@/types/transaction-enums';
import TransactionService from '@/services/Transaction.service';
import UserService from '@/services/User.service';
import { User } from '@/types/user-types';

@Component
export default class SendMoneyDialog extends Vue {
  @Prop() readonly model!: boolean;

  @Prop() readonly availableAmount!: number;

  @Prop({
    default: '',
  }) readonly userEmail!: string;

  public guardedClose(): void {
    if (!this.loading) {
      this.$emit('click:outside');
    }
  }

  public loading = true;

  public selectedUser: {id: number; name: string;} = {
    id: -1,
    name: 'Choose an Officer',
  }

  public users: Array<{id: number; name: number;}> = [];

  public transactionAmount = 0;

  public transactionNote = '';

  public async makeTransaction(): Promise<void> {
    if (this.transactionAmount > 0
      && this.transactionAmount <= this.availableAmount
      && this.selectedUser.id !== -1
      && !Number.isNaN(this.transactionAmount)
      && !Number.isNaN(this.availableAmount)
      && !this.loading) {
      this.loading = true;
      const TService = new TransactionService();
      const transactionBody: any = {
        sender: {
          type: TRADER.OFFICER,
          id: -1,
        },
        recipient: {
          type: TRADER.OFFICER,
          id: this.selectedUser.id,
        },
        amount: this.transactionAmount,
        payment_method: PAYMENT.CASH,
        text: this.transactionNote || 'No note specified',
      };
      await TService.makeTransaction(transactionBody);
      this.loading = false;
      this.$emit('transaction-done');
      this.guardedClose();
      this.transactionAmount = 0;
      this.transactionNote = '';
      this.selectedUser = {
        id: -1,
        name: 'Choose an Officer',
      };
    }
  }

  get conditions(): boolean {
    return (this.transactionAmount > 0
      && this.transactionAmount <= this.availableAmount
      && this.selectedUser.id !== -1
      && !Number.isNaN(this.transactionAmount)
      && !Number.isNaN(this.availableAmount));
  }

  async mounted(): Promise<void> {
    const toRemove: string[] = [
      'comp_acc@application.org',
      this.$store.state.User.email,
    ];

    this.loading = true;
    const UService = new UserService();
    const response = await UService.getAll();
    response.data = response.data.filter((user: any) => !toRemove.includes(user.email));
    console.log(response.data);
    this.users = response.data
      .filter((user: User) => user.email !== this.userEmail)
      .map((user: any) => ({
        id: user.id,
        name: user.name,
      }));
    this.loading = false;
  }
}
