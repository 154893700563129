





























































































































































import 'reflect-metadata';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Task, Service } from '@/types/pst-types';
import ServiceFormElement from '@/components/application/PackagesAndServices/ServiceFormElement.vue';

@Component({
  components: { ServiceFormElement },
})
export default class NewService extends Vue {
  FormValid = false;

  Loading = false;

  FormLoading = false;

  FormService = new Service();

  ConsistencyKeys: number[] = [];

  /* TODO: Remove hard-coded entries */
  /* HARD-CODED SECTION - TO REDO AT FIRST REFACTORING SESSION */

  TaskTypeMap: Record<string, number> = {
    checkbox: 1,
    text: 2,
    file: 3,
    finance: 4,
  };

  /* END SECTION */

  async createService() {
    /* HARD-CODED SECTION - TO REDO AT FIRST REFACTORING SESSION */
    this.FormService.tasks.forEach((task: any) => {
      // eslint-disable-next-line camelcase,no-param-reassign
      task.type_id = this.TaskTypeMap[task.type];
      // eslint-disable-next-line no-param-reassign
      task.text = task.name;
    });
    /* END SECTION */
    this.FormService.status = 'active';
    try {
      this.FormLoading = true;
      await this.FormService.save();
      this.FormLoading = false;
      await this.$router.push({ name: 'Packages & Services' });
    } catch (error) {
      console.log(error);
    }
  }

  appendNewTask() {
    const newTask = new Task();
    newTask.fromRaw({
      finance: 0,
      type: 'checkbox',
      order: this.FormService.tasks.length,
    });
    this.ConsistencyKeys.push(Date.now() + Math.random());
    this.FormService.tasks.push(newTask);
  }

  removeTask(index: number) {
    this.FormService.tasks.splice(index, 1);
    this.ConsistencyKeys.splice(index, 1);
  }

  updateTaskState(newState: Record<string, any>, index: number) {
    this.FormService.tasks[index].fromRaw(newState);
  }

  ValidationRules: Record<string, Array<(v: string) => boolean | string>> = {
    RequiredRules: [
      (v: string): boolean | string => !!v || 'First name required!',
    ],
    EmailRules: [
      (v: string): boolean | string => !!v || 'Last name required!',
      (v: string): boolean | string => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    PasswordRules: [
      (v: string): boolean | string => !!v || 'Password address required!',
      (v: string): boolean | string => v.length > 5 || 'Password must be at leas 5 characters!',
    ],
    PhoneRules: [
      (v: string): boolean | string => !!v || 'Phone number required!',
    ],
  };
}
