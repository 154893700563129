






















































































































































































/* eslint-disable import/no-duplicates */
import Vue from 'vue';
import Component from 'vue-class-component';
import CustomerService from '@/services/Customer.service';
import { Customer, CustomerBuilder } from '@/types/customer-types';
import parsePhoneNumber, { CountryCode, PhoneNumber } from 'libphonenumber-js';

@Component
export default class NewLead extends Vue {
  LocalLead = new Customer();

  Builder = new CustomerBuilder(this.LocalLead);

  RequestLoading = false;

  FormValid = false;

  PhoneCountry: CountryCode = 'GB';

  PhoneCountries: CountryCode[] = this.$store.state.General.phoneCountries;

  LocalPhoneNumber(): PhoneNumber | undefined {
    return parsePhoneNumber(
      this.LocalLead.phone,
      this.PhoneCountry,
    );
  }

  ValidationRules: Record<string, Array<(v: string) => boolean | string>> = {
    FirstNameRules: [
      (v: string): boolean | string => !!v || 'First name required!',
    ],
    LastNameRules: [
      (v: string): boolean | string => !!v || 'Last name required!',
    ],
    EmailRules: [
      // (v: string): boolean | string => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    PhoneRules: [
    ],
  };

  mounted() {
    this.Builder.Status('lead')
      .FirstName('')
      .LastName('')
      .Email('')
      .Phone('');
  }

  FormConditions = {
    UniqueEmail: true,
    UniquePhone: true,
    AtLeastOne: false,
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  OneTimeWatchers: Record<string, Function> = {
    EmailUnwatch: () => null,
    PhoneUnwatch: () => null,
  };

  async createLead() {
    const cs = new CustomerService();

    try {
      this.RequestLoading = true;
      const ProcessedLead = this.LocalLead;
      if (this.LocalLead.phone) {
        ProcessedLead.phone = this.LocalPhoneNumber()!.number as string;
      }
      const FD = new FormData();
      FD.append('json_data', JSON.stringify({
        type: ProcessedLead.status,
        customer: ProcessedLead,
      }));
      await cs.makeSingle(FD);
      this.RequestLoading = false;
      /* TODO: Either pass additional params or signal on
               this page that the creation was successful */
      this.$router.push({ name: 'Customer Index' });
    } catch (error) {
      console.log(error);
      this.RequestLoading = false;
      this.FormValid = false;
      const { errors } = error.response.data;
      if ('customer.email' in errors) {
        this.FormConditions.UniqueEmail = false;
        this.OneTimeWatchers.EmailUnwatch = this.$watch(
          () => this.LocalLead.email,
          () => {
            this.FormConditions.UniqueEmail = true;
            this.OneTimeWatchers.EmailUnwatch();
          },
        );
      }

      if ('customer.phone' in errors) {
        this.FormConditions.UniquePhone = false;
        this.OneTimeWatchers.PhoneUnwatch = this.$watch(
          () => this.LocalLead.phone,
          () => {
            this.FormConditions.UniquePhone = true;
            this.OneTimeWatchers.PhoneUnwatch();
          },
        );
      }
    }
  }

  get AtLeastOne() {
    if (!!this.LocalLead.phone || !!this.LocalLead.email) return [];
    return 'At least one of these fields must be filled';
  }

  get UniqueEmailError() {
    if (this.FormConditions.UniqueEmail
       && (!!this.LocalLead.phone || !!this.LocalLead.email)
       && (/.+@.+\..+/.test(this.LocalLead.email) || !this.LocalLead.email)) {
      return [];
    }
    if (!this.FormConditions.UniqueEmail) {
      return 'Email must be unique!';
    }
    if (!/.+@.+\..+/.test(this.LocalLead.email) && !!this.LocalLead.email) {
      return 'The email is not valid';
    }
    return 'At least one of these fields must be filled';
  }

  get UniquePhoneError() {
    let PN: boolean = (this.LocalLead.phone && this.LocalLead.phone !== '0')
      ? this.LocalPhoneNumber()!.isValid() : false;
    if (!this.LocalLead.phone) {
      PN = true;
    }
    if (PN
      && this.FormConditions.UniquePhone
      && (!!this.LocalLead.phone || !!this.LocalLead.email)) {
      return [];
    }
    if (!this.FormConditions.UniquePhone) {
      return 'Phone must be unique!';
    }
    if (!this.LocalLead.phone && !this.LocalLead.email) {
      return 'At least one of these fields must be filled';
    }
    return 'The given phone number is invalid';
  }
}
