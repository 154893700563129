
































import 'reflect-metadata';
// eslint-disable-next-line object-curly-newline
import { Vue, Component, Prop } from 'vue-property-decorator';
import SessionService from '@/services/Session.service';

@Component
export default class DeleteTrasactionDialog extends Vue {
  @Prop() readonly model!: boolean;

  @Prop() readonly sessionId!: number;

  @Prop() readonly sthId!: number;

  @Prop() readonly customerId!: number;

  public guardedClose(): void {
    if (!this.loading) {
      this.$emit('click:outside');
    }
  }

  public loading = false;

  async deleteTransaction() {
    const SS = new SessionService();
    console.log(
      {
        session_id: this.sessionId,
        customer_id: this.customerId,
        sth_id: this.sthId,
      },
    );
    await SS.removeCustomerFromSession({
      session_id: this.sessionId,
      customer_id: this.customerId,
      sth_id: this.sthId,
    });
    this.guardedClose();
    this.$emit('deletion-done');
  }
}
