














































































import 'reflect-metadata';
// eslint-disable-next-line object-curly-newline
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import PSTService from '@/services/PST.service';

@Component
export default class SplitTaskDialog extends Vue {
  @Prop() readonly model!: boolean;

  @Prop() readonly fullValue!: number;

  @Prop() readonly taskId!: number;

  public realFirstValue: number = this.fullValue;

  public manualSecondValue = 0.0;

  public overwrite = false;

  /**
   * Watcher used to update the full value
   * whenever a new financial task
   * is to be split.
   * @param newVal
   * @param oldVal
   */
  @Watch('model')
  onModelChanged(newVal: boolean, oldVal: boolean) {
    if (!oldVal && newVal) {
      this.firstValue = this.fullValue;
    }
  }

  /**
   * NOTE: This is only used for display values. Don't use this getter
   * when sending requests! Use realFirstValue instead, which, as mentioned, holds
   * the real value.
   */
  get firstValue() {
    if (Math.sign(this.fullValue) === -1) {
      return -1 * this.realFirstValue;
    }
    return this.realFirstValue;
  }

  set firstValue(value: number) {
    const absValue = Math.abs(value);
    if (Math.sign(this.fullValue) === -1) {
      this.realFirstValue = -1 * absValue;
      return;
    }
    this.realFirstValue = absValue;
  }

  get secondValue() {
    if (this.overwrite) {
      return this.manualSecondValue;
    }
    if (Number.isNaN(this.firstValue)) {
      return this.fullValue;
    }
    if (Math.sign(this.fullValue) === -1) {
      return Math.abs(this.fullValue - this.realFirstValue);
    }
    return this.fullValue - this.realFirstValue;
  }

  /**
   * NOTE: This is only used for display values. Don't use this getter
   * when sending requests! Use realSecondValue instead, which, as mentioned, holds
   * the real value.
   */
  set secondValue(value) {
    if (this.overwrite) {
      if (Math.sign(this.fullValue) === -1) {
        this.manualSecondValue = Math.abs(value);
        return;
      }
      this.manualSecondValue = value;
    }
  }

  get realSecondValue() {
    if (Math.sign(this.fullValue) === -1) {
      return this.secondValue * -1;
    }
    return this.secondValue;
  }

  get conditions() {
    if (Number.isNaN(this.firstValue) || Number.isNaN(this.secondValue)) {
      return true;
    }
    if (this.realFirstValue * this.realSecondValue < 0) {
      return true;
    }
    if (this.realFirstValue * this.fullValue < 0) {
      return true;
    }
    if (this.realSecondValue * this.fullValue < 0) {
      return true;
    }
    if (!this.overwrite && this.realSecondValue
      + this.realFirstValue !== this.fullValue) {
      console.log('oh no');
      return true;
    }
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  public plusMinusColor(finance: number): string {
    if (Math.sign(finance) === -1) return 'red';
    return 'green';
  }

  public async split() {
    const requestParams = {
      task_id: this.taskId,
      new_values: [this.realFirstValue, this.realSecondValue],
    };
    const PSTS = new PSTService();
    await PSTS.splitTask(requestParams);
    this.$router.go(0);
  }
}
