/* eslint-disable camelcase */
import PSTService from '@/services/PST.service';
import { AxiosPromise, AxiosResponse } from 'axios';
import { assignIfDefined } from '@/util/assign-if-defined.util';

interface SubmitArguments {
  package_id: number;
  customer_id: number;
}

export default class Collaborator {
  /* DATA RELATED FIELDS */
  public id = -1;

  public amount = -1;

  public name = 'Name not fetched';

  public state = false;

  public payment_method = 0;

  public all: Array<Record<string, string | number>> = [];

  public database_id = -1;

  public database_amount = -1;

  public database_state = false;

  public database_payment_method = 0;

  /* REACTIVITY RELATED FIELDS */
  public loading = false;

  get amountFrozen(): boolean {
    return this.database_state;
  }

  public async submit(sa: SubmitArguments) {
    const PSTS = new PSTService();
    this.loading = true;
    try {
      await PSTS.executeCollaboratorTransaction({
        collaborator_id: this.id,
        package_id: sa.package_id,
        customer_id: sa.customer_id,
        amount: this.amount,
        state: this.state,
        payment_method: this.payment_method,
      });
      this.database_id = this.id;
      this.database_amount = this.amount;
      this.database_state = this.state;
      this.database_payment_method = this.payment_method;
    } catch (e) {
      console.log(e);
    }
    this.loading = false;
  }

  get submitAvailable() {
    return this.id !== -1
    && this.amount !== -1;
  }

  private reflect() {
    this.id = this.database_id;
    this.amount = this.database_amount;
    this.state = this.database_state;
    this.payment_method = this.database_payment_method;
  }

  public fromRaw(c: any) {
    const assignIfAvailable = assignIfDefined.bind(this);
    assignIfAvailable('database_id', c.collaborator.id);
    assignIfAvailable('database_amount', c.amount);
    assignIfAvailable('database_state', c.state);
    assignIfAvailable('database_payment_method', c.payment_method);
    this.reflect();
  }
}
