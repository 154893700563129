/* eslint-disable object-curly-newline */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { RawUser } from '@/types/user-types.interface';

/** TODO:
 *    Consider renaming the actions and mutation
 *    to match with all modules and follow a
 *    naming standard
 */

@Module({ namespaced: true })
class User extends VuexModule {
  public Email = '';

  public Token = '';

  public Name = '';

  public ID = 0;

  public UserIsLoggedIn = false;

  public Permissions: Array<string> = [];

  @Mutation
  public setEmail(newEmail: string): void {
    this.Email = newEmail;
  }

  @Mutation
  public setToken(newToken: string): void {
    this.Token = newToken;
  }

  @Mutation
  public setName(newName: string): void {
    this.Name = newName;
  }

  @Mutation
  public toggleUserIsLoggedIn(): void {
    this.UserIsLoggedIn = !this.UserIsLoggedIn;
  }

  @Mutation
  public setUserIsLoggedIn(value: boolean): void {
    this.UserIsLoggedIn = value;
  }

  @Mutation
  public appendPermission(permission: string) {
    this.Permissions.push(permission);
  }

  @Mutation
  public setPermissions(pArray: Array<string>) {
    this.Permissions = [...pArray];
  }

  @Mutation
  public setID(id: number) {
    this.ID = id;
  }

  @Action({ rawError: true })
  public login(d: RawUser): Promise<void> {
    return new Promise((resolve, reject) => {
      this.context.commit('setEmail', d.email);
      this.context.commit('setToken', d.token);
      if (!this.UserIsLoggedIn) {
        this.context.commit('setUserIsLoggedIn', true);
      }
      resolve();
    });
  }

  @Action({ rawError: true })
  public logout(): void {
    this.context.commit('setEmail', '');
    this.context.commit('setToken', '');
    this.context.commit('setID', 0);
    if (this.UserIsLoggedIn) {
      this.context.commit('setUserIsLoggedIn', false);
    }
  }
}

export default User;
