/* eslint-disable camelcase */
import { AxiosPromise } from 'axios';
import UserService from '@/services/User.service';
import { Location } from '@/types/location-types';
import { MissingFieldsError } from '@/util/missing-fields-error.util';
import { RawUser } from '@/types/user-types.interface';
import { assignIfDefined } from '@/util/assign-if-defined.util';

/**
 * @class User
 * Used for any user-related API actions.
 * Fields are nullable and some actions may be unavailable at times,
 * throwing exceptions on such occasions.
 */
export class User implements RawUser {
  email = '';

  location: Location | undefined = undefined;

  location_id = -1;

  name = '';

  permission_level = -1;

  status = '';

  token = '';

  password = '';

  fromRaw(rd: RawUser | any): void {
    const assignIfAvailable = assignIfDefined.bind(this);
    assignIfAvailable<string>('email', rd.email);
    assignIfAvailable<Location>('location', rd.location);
    assignIfAvailable<number>('location_id', rd.location_id);
    assignIfAvailable<string>('name', rd.name);
    assignIfAvailable<number>('permission_level', rd.permission_level);
    assignIfAvailable<string>('status', rd.status);
    assignIfAvailable<string>('token', rd.token);
    assignIfAvailable<string>('password', rd.password);
  }

  /**
   * @method toRaw
   * Used for API interop. This method uses loose semantics in order
   * to have a cherry-pick system for the end user, which formats the
   * returned data to JSON and ships it with a request to the API.
   */
  toRaw(props: Array<keyof RawUser>): RawUser {
    const constructedRawUser: {[K in keyof RawUser]: any} = {};
    props.forEach((key) => {
      constructedRawUser[key] = this[key];
    });
    return constructedRawUser;
  }

  archive(): AxiosPromise {
    if (!this.email) {
      throw new MissingFieldsError('Some or all required fields for user creation are not set.');
    }

    const UService = new UserService();

    const rawUser: RawUser = this.toRaw([
      'email',
    ]);

    return UService.archiveSingle(rawUser);
  }

  login(): AxiosPromise {
    if (!(this.email
        && this.password)) {
      throw new MissingFieldsError('Some or all required fields for user creation are not set.');
    }

    const UService = new UserService();

    const rawUser: RawUser = this.toRaw([
      'email',
      'password',
    ]);

    return UService.login(rawUser);
  }

  save = (): AxiosPromise => {
    // TODO: Consider setting up validator decorators here.
    if (!(this.email
        && this.location_id !== -1
        && this.name
        && this.password
        && this.permission_level !== -1)) {
      throw new MissingFieldsError('Some or all required fields for user creation are not set.');
    }

    const UService = new UserService();

    const rawUser: RawUser = this.toRaw([
      'email',
      'location_id',
      'name',
      'password',
      'permission_level',
      'status',
    ]);

    return UService.makeSingle(rawUser);
  }

  get role(): AxiosPromise {
    if (!this.email) {
      throw new MissingFieldsError('Some or all required fields for user creation are not set.');
    }

    const UService = new UserService();

    const rawUser: RawUser = this.toRaw([
      'email',
    ]);

    return UService.getRole(rawUser);
  }
}

/**
 * @class UserBuilder
 * Used for selective construction of User objects.
 */
export class UserBuilder {
  private readonly _user: User;

  public constructor(u: User) {
    this._user = u;
  }

  get user(): User {
    return this._user;
  }

  public Email(e: string) {
    this._user.email = e;
    return this;
  }

  public Location(l: Location) {
    this._user.location = l;
    return this;
  }

  public LocationId(li: number) {
    this._user.location_id = li;
    return this;
  }

  public Name(n: string) {
    this._user.name = n;
    return this;
  }

  public PermissionLevel(pl: number) {
    this._user.permission_level = pl;
    return this;
  }

  public Status(s: string) {
    this._user.status = s;
    return this;
  }

  public Token(t: string) {
    this._user.token = t;
    return this;
  }

  public Password(p: string) {
    this._user.password = p;
    return this;
  }
}
