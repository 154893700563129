/* eslint-disable implicit-arrow-linebreak, camelcase */
import HttpClient from '@/services/Api.service';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

interface DateParams {
  day: number;
  month: number;
  year: number;
}

interface AssignationParams extends DateParams {
  sth_id: number;
  customer_id: number;
  package_id: number;
}

interface PaginateParams {
  page?: number;
  order?: string;
}

interface AvailableFilesParams {
  session_date: string;
  sth_id: number;
}

interface RemoveCustomerFromSessionParams {
  session_id: number;
  sth_id: number;
  customer_id: number;
}

class SessionService extends HttpClient {
  public constructor() {
    super((process.env.VUE_APP_BASE_URL)!);
  }

  public getAllSessions = () =>
    this.instance.get('/get-all-sessions');

  public getSingleSession = (params: DateParams) =>
    this.instance.get('/get-single-session', { params })

  public createSession = (params: DateParams) =>
    this.instance.post('/create-session', params);

  public assignServiceToSession = (params: AssignationParams) =>
    this.instance.post('/assign-service-to-session', params);

  public paginateAllSessions = (params: PaginateParams = {}) =>
    this.instance.get('/paginate-sessions', { params });

  public getAvailableFiles = (params: AvailableFilesParams) =>
    this.instance.get('/get-available-files', { params });

  public massDownload = (params: any) =>
    this.instance.post('/mass-download', params, {
      responseType: 'blob',
    });

  public removeCustomerFromSession = (params: RemoveCustomerFromSessionParams) =>
    this.instance.delete('/remove-customer-from-session', { params });
}

export default SessionService;
