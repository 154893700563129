











































































































import { Vue, Component } from 'vue-property-decorator';
import SessionService from '@/services/Session.service';
import Loading from '@/components/application/Loading.vue';
import CustomerService from '@/services/Customer.service';
import { AxiosResponse } from 'axios';
import { Customer } from '@/types/customer-types';

@Component({
  components: {
    Loading,
  },
})
export default class SessionDashboard extends Vue {
  LoadingState = true;

  /* eslint-disable camelcase */
  Sessions: {
    id: number;
    date: string;
    customer_count?: number;
    specific_count: {
      sth_id: number;
      package_id: number;
      name: string;
      total: number;
    };
  }[] = [];
  /* eslint-enable camelcase */

  SessionDates: {date: Date; formatted: string}[] = [];

  async mounted() {
    this.paginate();
  }

  LoadingResults = false;

  TotalPages = 1;

  CurrentPage = 1;

  async paginate() {
    const SS = new SessionService();
    let response: AxiosResponse;
    try {
      this.LoadingResults = true;
      response = await SS.paginateAllSessions();
      this.LoadingResults = false;
      const SessionData = response.data.data;
      this.TotalPages = response.data.last_page;
      this.CurrentPage = 1;
      this.Sessions = [];
      SessionData.forEach((session: any) => {
        this.Sessions.push(session);
        const date = new Date(session.date);
        const formatted = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;

        this.SessionDates.push({
          date,
          formatted,
        });
      });
      this.LoadingResults = false;
      this.LoadingState = false;
    } catch (error) {
      console.log(error);
    }
  }

  async moveToPage(e: string, f?: string[]) {
    const SS = new SessionService();
    let response: AxiosResponse;
    try {
      this.LoadingResults = true;
      response = await SS.paginateAllSessions({
        page: parseInt(e, 10),
      });
      this.LoadingResults = false;
      const SessionData = response.data.data;
      this.TotalPages = response.data.last_page;
      this.CurrentPage = parseInt(e, 10);
      this.Sessions = [];
      this.SessionDates = [];
      SessionData.forEach((session: any) => {
        this.Sessions.push(session);
        const date = new Date(session.date);
        const formatted = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;

        this.SessionDates.push({
          date,
          formatted,
        });
      });
      this.LoadingResults = false;
      this.LoadingState = false;
    } catch (error) {
      console.log(error);
    }
  }
}
