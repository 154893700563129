






























































































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import CustomerService from '@/services/Customer.service';
import DocumentService from '@/services/Document.service';
import { Customer } from '@/types/customer-types';
import { CustomerIDocument, IDocumentType } from '@/types/document-types';
import UtilityBar from '@/components/application/UtilityBar/UtilityBar.vue';
import { AxiosResponse } from 'axios';
import PSCard from '@/components/application/PackagesAndServices/PSCard.vue';
import Loading from '@/components/application/Loading.vue';
import CustomerDocument from '@/components/application/CustomerProfile/CustomerDocument.vue';
import { Package } from '@/types/pst-types';
import { BarAction } from '@/components/application/UtilityBar/utility-bar-types';
import NotesDialog, { Note } from '@/components/application/Customer/NotesDialog.vue';
import CustomerFilesDialog from '@/components/application/Customer/CustomerFilesDialog.vue';
import TransactionService from '@/services/Transaction.service';
import { TRADER } from '@/types/transaction-enums';
import DocumentUpdateDialog from '@/components/application/Customer/DocumentUpdateDialog.vue';
import { Watch } from 'vue-property-decorator';

@Component({
  components: {
    DocumentUpdateDialog,
    PSCard,
    Loading,
    CustomerDocument,
    UtilityBar,
    NotesDialog,
    CustomerFilesDialog,
  },
})
export default class CustomerProfile extends Vue {
  public barChunks: BarAction[] = [
    {
      icon: 'mdi-text-box',
      text: 'DETAILS',
      eventName: 'go-to-details',
    },
    {
      icon: 'mdi-account-search',
      text: 'SHOW JOURNEY',
      eventName: 'show-journey',
    },
    {
      icon: 'mdi-pencil',
      text: 'FIX CONTACT',
      eventName: 'fix-details',
    },
    {
      icon: 'mdi-note-outline',
      text: 'NOTES & SMS',
      eventName: 'notes-start',
      badgeValue: '',
    },
  ];

  DocumentUpdateModal = false;

  DoctypeId = -1;

  CustomerFilesModal = false;

  CustomerFiles: string[] = [];

  public openDocumentUpdate(typeId = -1): void {
    this.DoctypeId = typeId;
    this.DocumentUpdateModal = true;
  }

  public closeDocumentUpdate(event: Event, typeId = -1): void {
    this.DocumentUpdateModal = false;
  }

  public openCustomerFiles(): void {
    this.CustomerFilesModal = true;
  }

  public closeCustomerFiles(): void {
    this.CustomerFilesModal = false;
  }

  NotesModal = false;

  Notes: Note[] = [];

  NoteCount = 0;

  @Watch('NoteCount')
  public onNoteCountChanged(newVal: number) {
    this.barChunks[3].badgeValue = newVal;
  }

  public openNotes(): void {
    this.NotesModal = true;
  }

  public closeNotes(): void {
    this.NotesModal = false;
  }

  public async updateNotes(): Promise<any> {
    const C = new CustomerService();
    const customerNotes = C.getNotes({ customer_id: parseInt(this.$route.params.id, 10) });
    const response = await customerNotes;
    this.Notes = [];
    response.data.forEach((note: Note) => {
      this.Notes.push(note);
    });
    this.NoteCount = this.Notes.length;
  }

  CustomerData = new Customer();

  DisplayData = new Array<Record<string, string>>();

  DocumentTypes = new Array<IDocumentType>();

  CreationDate = '';

  Loading = true;

  Avatar = 0;

  Documents = new Array<CustomerIDocument>();

  Packages: Package[] = [];

  CustomerPackages: Package[] = [];

  goToJourney() {
    this.$router.push({ path: `/application/customer/journey/${this.$route.params.id}` });
  }

  goToDetails() {
    this.$router.push({ path: `/application/customer-details/${this.$route.params.id}` });
  }

  goToEdit() {
    if (!this.Loading) {
      const status = this.CustomerData.status.toLowerCase();
      this.CustomerData.id = parseInt(this.$route.params.id, 10);
      this.$router.push(`/application/edit-customer/${this.$route.params.id}`);
    }
  }

  goToUpgrade() {
    if (!this.Loading && this.CustomerData.status.toLowerCase() === 'lead') {
      const name = 'Upgrade Lead to Customer';
      this.CustomerData.id = parseInt(this.$route.params.id, 10);
      this.$router.push({
        name,
        params: {
          data: encodeURIComponent(JSON.stringify(this.CustomerData)),
        },
      });
    }
  }

  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,class-methods-use-this
  async goToPackages() {
    await this.$router.push(
      {
        name: 'Customer Packages',
        params: {
          id: this.$route.params.id,
        },
      },
    );
  }

  // eslint-disable-next-line class-methods-use-this
  public parseDate(input: string): string {
    const d = new Date(input);
    return `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`;
  }

  TableData: any[] = [];

  CurrentPage = 1;

  TotalPages = 0;

  async moveToPage(e: string, f?: string[]) {
    const TService = new TransactionService();

    let response: AxiosResponse;

    const paginateFunction: (params: any) => Promise<AxiosResponse> = TService.getWallet;

    this.TableData = [];
    try {
      const params = {
        page: parseInt(e, 10),
        order: 'descending',
        wallet_id: this.CustomerData.id,
        wallet_type: TRADER.CUSTOMER,
      };

      response = await paginateFunction(params);
      const TransactionData = response.data.entries.data;
      this.CurrentPage = parseInt(e, 10);
      TransactionData.forEach((customer: any) => {
        this.TableData.push(customer);
      });
    } catch (error) {
      console.log(error);
    }
  }

  async mounted() {
    this.Packages = [];

    const CService = new CustomerService();
    const DService = new DocumentService();
    const TService = new TransactionService();
    let documentResponse: AxiosResponse;
    try {
      this.Loading = true;
      const singleCustomer = CService.getSingle({ id: parseInt(this.$route.params.id, 10) });
      const customerNotes = CService.getNotes({ customer_id: parseInt(this.$route.params.id, 10) });

      const customerResponse = await singleCustomer;
      this.CustomerData.fromRaw(customerResponse!.data);

      let customerFiles: Promise<AxiosResponse> | undefined;
      if (this.CustomerData.status.toLowerCase() === 'customer') {
        customerFiles = CService.getFiles({ customer_id: parseInt(this.$route.params.id, 10) });
      }

      const CUSTOMER_REQUESTS = [
        DService.getAllTypes(),
        customerNotes,
        customerFiles,
        CService.getDetails({
          customer_id: parseInt(this.$route.params.id, 10),
        }),
        TService.getWallet({
          wallet_id: parseInt(this.$route.params.id, 10),
          wallet_type: TRADER.CUSTOMER,
        }),
      ];

      const LEAD_REQUESTS = [
        DService.getAllTypes(),
        customerNotes,
        CService.getDetails({
          customer_id: parseInt(this.$route.params.id, 10),
        }),
      ];

      const responses = await Promise.all(
        this.CustomerData.status.toLowerCase() === 'customer'
          ? CUSTOMER_REQUESTS : LEAD_REQUESTS,
      );

      const [doctypeResponse, noteResponse] = responses;
      let fileResponse;
      let detailResponse;
      let transactionResponse;

      if (this.CustomerData.status.toLowerCase() === 'customer') {
        // eslint-disable-next-line prefer-destructuring
        fileResponse = responses[2];
        await fileResponse!.data.forEach((filename: string) => {
          this.CustomerFiles.push(filename);
        });
        // eslint-disable-next-line prefer-destructuring
        detailResponse = responses[3];
        // eslint-disable-next-line prefer-destructuring
        transactionResponse = responses[4];
      } else {
        // eslint-disable-next-line prefer-destructuring
        detailResponse = responses[2];
      }

      const TransactionData = transactionResponse?.data?.entries?.data;
      this.TotalPages = transactionResponse?.data?.entries?.last_page;
      if (typeof TransactionData !== 'undefined') {
        TransactionData.forEach((transaction: any) => {
          this.TableData.push(transaction);
        });
      }

      await noteResponse!.data.forEach((note: Note) => {
        this.Notes.push(note);
      });

      this.NoteCount = this.Notes.length;

      this.Avatar = customerResponse!.data.id;
      this.CreationDate = customerResponse!.data.created_at;

      documentResponse = await CService.getBelongingDocuments(
        { id: parseInt(this.$route.params.id, 10) },
      );

      documentResponse.data.filter((doc: CustomerIDocument) => doc.status === 'valid')
        .forEach((document: any) => {
          const tDocument = new CustomerIDocument();
          tDocument.fromRaw(document);
          this.Documents.push(tDocument);
        });

      this.DisplayData.push(
        {
          name: 'STATUS',
          data: this.CustomerData.status.toUpperCase(),
        },
        {
          name: 'FIRST NAME',
          data: this.CustomerData.first_name,
        },
        {
          name: 'LAST NAME',
          data: this.CustomerData.last_name,
        },
        {
          name: 'EMAIL ADDRESS',
          data: this.CustomerData.email,
        },
        {
          name: 'PHONE NUMBER',
          data: this.CustomerData.phone,
        },
        {
          name: 'LOCATION',
          data: this.CustomerData.location!.name,
        },
      );

      detailResponse!.data.forEach((detail: any) => {
        this.DisplayData.push({
          name: detail.name || 'N/A',
          data: detail.value,
        });
      });

      /**
       * Related to barChunks: BarActions[]
       */
      if (this.CustomerData.status === 'lead') {
        this.barChunks.push(
          {
            icon: 'mdi-arrow-up-circle',
            text: 'UPGRADE',
            eventName: 'upgrade-lead',
          },
        );
      } else if (this.CustomerData.status === 'customer') {
        this.barChunks.push(
          {
            icon: 'mdi-package-variant-closed',
            text: 'PACKAGES',
            eventName: 'packages',
          },
          {
            icon: 'mdi-file-document-multiple-outline',
            text: 'FILES',
            eventName: 'customer-files',
          },
        );
      }

      await doctypeResponse!.data.forEach((doctype: any) => {
        this.DocumentTypes.push(new IDocumentType(doctype.id, doctype.name, doctype.description));
      });

      this.Loading = false;
    } catch (error) {
      console.log(error);
    }
  }

  async assignPackage(id: number) {
    const CService = new CustomerService();
    await CService.assignPackage({
      id: this.$route.params.id,
      // eslint-disable-next-line camelcase
      package_id: id,
    });
    this.$router.go(0);
  }
}
