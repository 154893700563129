










































/* eslint-disable object-curly-newline */
import 'reflect-metadata';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class StringSearch extends Vue {
  @Prop({
    default: 'N/A',
  }) readonly field!: string;

  @Prop({
    default: '',
  }) readonly name!: string;

  public dataModel = '';

  showState = false;

  update(): void {
    if (this.dataModel === '') {
      this.$emit('unset-filters', [this.field]);
    } else {
      this.$emit('set-filters', [{
        field: this.field,
        operator: 'like',
        operand: this.dataModel,
        display: this.dataModel,
      }]);
    }
    setTimeout(() => { this.$emit('input', false); }, 400);
  }
}
