















































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import CustomerService from '@/services/Customer.service';
import { AxiosResponse } from 'axios';
import UtilityBar from '@/components/application/UtilityBar/UtilityBar.vue';
import { BarAction } from '@/components/application/UtilityBar/utility-bar-types';

@Component(
  {
    components: {
      UtilityBar,
    },
  },
)
export default class CustomerJourney extends Vue {
  barChunks: BarAction[] = [
    {
      icon: 'mdi-arrow-left',
      text: 'BACK TO PROFILE',
      eventName: 'back-to-profile',
    },
  ];

  Logs = new Array<Record<string, any>>();

  Loading = true;

  Hidden: boolean[] = [];

  goToProfile() {
    this.$router.push({ path: `/application/customer/${this.$route.params.id}` });
  }

  toggleHidden(index: number) {
    this.$set(this.Hidden, index, !this.Hidden[index]);
  }

  async mounted() {
    const CService = new CustomerService();
    let response: AxiosResponse;
    try {
      this.Loading = true;

      response = await CService.getJourney({ id: parseInt(this.$route.params.id, 10) });
      this.Logs = response.data;
      for (let i = 0; i < this.Logs.length; i += 1) {
        this.Hidden.push(false);
      }
      this.Hidden = [...this.Hidden];

      this.Loading = false;
    } catch (error) {
      console.log(error);
    }
  }
}
