




























































































































































































/* eslint-disable import/no-duplicates */
import Vue from 'vue';
import Component from 'vue-class-component';
import { Customer, CustomerBuilder } from '@/types/customer-types';
import { PhoneNumber, CountryCode } from 'libphonenumber-js';
import parsePhoneNumber from 'libphonenumber-js';
import { BarAction } from '@/components/application/UtilityBar/utility-bar-types';
import UtilityBar from '@/components/application/UtilityBar/UtilityBar.vue';
import CustomerService from '@/services/Customer.service';
import { AxiosResponse } from 'axios';

type VuetifyDoctypeSelect<T> = {
  [P in keyof T]: T[P];
} & {
  text: string;
}

@Component({
  components: {
    UtilityBar,
  },
})
export default class EditCustomer extends Vue {
  barChunks: BarAction[] = [
    {
      icon: 'mdi-arrow-left',
      text: 'BACK TO CUSTOMER',
      eventName: 'back-to-profile',
    },
  ];

  async goBackToProfile() {
    await this.$router.push(`/application/customer/${this.$route.params.id}`);
  }

  ID = -1;

  OriginalCustomer = new Customer();

  LocalCustomer = new Customer();

  Builder = new CustomerBuilder(this.LocalCustomer);

  Loading = true;

  RequestLoading = false;

  FormValid = false;

  PhoneCountry: CountryCode = 'GB';

  PhoneCountries: CountryCode[] = this.$store.state.General.phoneCountries;

  LocalPhoneNumber(): PhoneNumber | undefined {
    return parsePhoneNumber(
      this.LocalCustomer.phone,
      this.PhoneCountry,
    );
  }

  ValidationRules: Record<string, Array<(v: string) => boolean | string>> = {
    FirstNameRules: [
      (v: string): boolean | string => !!v || 'First name required!',
    ],
    LastNameRules: [
      (v: string): boolean | string => !!v || 'Last name required!',
    ],
    EmailRules: [
      (v: string): boolean | string => !v || /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    PhoneRules: [],
  };

  async mounted() {
    const CS = new CustomerService();
    if (typeof this.$route.params.id === 'undefined') return;
    const customerResponse: AxiosResponse = await CS.getSingle(
      { id: parseInt(this.$route.params.id, 10) },
    );
    console.log(customerResponse);
    this.Builder.Status('customer')
      .FirstName(customerResponse.data.first_name)
      .LastName(customerResponse.data.last_name)
      .Email(customerResponse.data.email)
      .Phone(customerResponse.data.phone);
    this.OriginalCustomer.fromRaw(this.LocalCustomer.toRaw([
      'status',
      'first_name',
      'last_name',
      'email',
      'phone',
      'location',
    ]));
    this.ID = customerResponse.data.id!;
  }

  FormConditions = {
    UniqueEmail: true,
    UniquePhone: true,
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  OneTimeWatchers: Record<string, Function> = {
    EmailUnwatch: () => null,
    PhoneUnwatch: () => null,
  };

  async editCustomer() {
    try {
      this.RequestLoading = true;

      const ProcessedCustomer = this.LocalCustomer;
      ProcessedCustomer.phone = this.LocalPhoneNumber()!.number as string;

      await this.OriginalCustomer.edit(this.LocalCustomer, this.ID);

      this.RequestLoading = false;
      /* TODO: Either pass additional params or signal on
               this page that the creation was successful */
      this.$router.go(-1);
    } catch (error) {
      this.RequestLoading = false;
      this.FormValid = false;

      if (error.response.data?.data?.email_duplicate) {
        this.FormConditions.UniqueEmail = false;
        this.OneTimeWatchers.EmailUnwatch = this.$watch(
          () => this.LocalCustomer.email,
          () => {
            this.FormConditions.UniqueEmail = true;
            this.OneTimeWatchers.EmailUnwatch();
          },
        );
      }

      if (error.response.data?.data?.phone_duplicate) {
        this.FormConditions.UniquePhone = false;
        this.OneTimeWatchers.PhoneUnwatch = this.$watch(
          () => this.LocalCustomer.phone,
          () => {
            this.FormConditions.UniquePhone = true;
            this.OneTimeWatchers.PhoneUnwatch();
          },
        );
      }
    }
  }

  get UniqueEmailError() {
    if (this.FormConditions.UniqueEmail) return [];
    return 'Email must be unique!';
  }

  get UniquePhoneError() {
    const PN: boolean = (this.LocalCustomer.phone && this.LocalCustomer.phone !== '0')
      ? this.LocalPhoneNumber()!.isValid() : false;
    if (PN
      && this.FormConditions.UniquePhone) {
      return [];
    }
    if (!this.FormConditions.UniquePhone) {
      return 'Phone must be unique!';
    }
    return 'The given phone number is invalid';
  }
}
