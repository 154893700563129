




























































import 'reflect-metadata';
// eslint-disable-next-line object-curly-newline
import { Vue, Component, Prop } from 'vue-property-decorator';
import CustomerService from '@/services/Customer.service';

@Component
export default class AddDetailModal extends Vue {
  @Prop() readonly model!: boolean;

  @Prop() readonly details!: Array<{
    id: number;
    name: string;
  }>;

  @Prop() readonly customerId!: number;

  public guardedClose(withRefresh = false): void {
    if (!this.loading) {
      if (withRefresh) {
        this.$emit('refresh');
      }
      this.$emit('click:outside');
    }
  }

  public loading = false;

  public detailValue = '';

  public detailId = -1;

  public async attachDetail(): Promise<void> {
    const CService = new CustomerService();
    const response = await CService.attachDetail({
      detail_id: this.detailId,
      customer_id: this.customerId,
      value: this.detailValue,
    });
    this.guardedClose(true);
  }
}
