















































import 'reflect-metadata';
// eslint-disable-next-line object-curly-newline
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import PSTService from '@/services/PST.service';

@Component
export default class DuplicateTaskDialog extends Vue {
  @Prop() readonly model!: boolean;

  @Prop() readonly initialValue!: number;

  @Prop() readonly taskId!: number;

  public overwrite = false;

  public newValue: number = this.initialValue;

  @Watch('model')
  onModelChanged(newVal: boolean, oldVal: boolean) {
    if (!oldVal && newVal) {
      this.newValue = this.initialValue;
    }
  }

  @Watch('overwrite')
  onOverwriteChanged(newVal: boolean) {
    if (!newVal) {
      this.newValue = this.initialValue;
    }
  }

  async duplicate() {
    const PSTS = new PSTService();
    await PSTS.duplicateTask({
      task_id: this.taskId,
      new_value: this.newValue,
    });
    this.$router.go(0);
  }
}
