




























































/* eslint-disable prefer-const */
import Component from 'vue-class-component';
import Vue from 'vue';
import { Action } from 'vuex-class';
import { AxiosResponse } from 'axios';
import BaseMaterialCard from '@/components/base/MaterialCard.vue';
import UserService from '@/services/User.service';
import { RawUser } from '@/types/user-types.interface';

@Component({
  components: {
    BaseMaterialCard,
  },
})
export default class Login extends Vue {
  valid = true;

  name = 'Login';

  email = '';

  password = '';

  ErrorMessage = '';

  ButtonLoadingState = false;

  emailRules: ((v: string) => boolean | string)[] = [
    (v: string) => !!v || 'E-mail is required',
    (v: string) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
  ];

  passwordRules: ((v: string) => boolean | string)[] = [
    (v: string) => !!v || 'Password is required',
  ];

  @Action('User/login')
  public login!: (d: RawUser) => Promise<any>;

  async attemptLogin() {
    this.ErrorMessage = '';
    let d: RawUser = {
      email: this.email,
      password: this.password,
      token: '',
    };
    let UService = new UserService();
    let response: AxiosResponse | string;
    try {
      this.ButtonLoadingState = true;
      response = await UService.login(d);
      d.token = response as unknown as string;
      await this.login(d);
      UService = new UserService();
      response = await UService.getRole({
        email: this.email,
      }) as AxiosResponse;
      const { permissions } = JSON.parse(response.data.permissions);
      this.$store.commit('User/setPermissions', permissions);
      this.$store.commit('User/setID', response.data.user_id);
      this.ButtonLoadingState = false;
      await this.$router.push({ name: 'Customer Index' });
    } catch (error) {
      this.ButtonLoadingState = false;
      if (typeof error.response === 'undefined') {
        this.ErrorMessage = 'Client error. Contact support.';
      } else {
        switch (error.response.status) {
          case 401: {
            this.ErrorMessage = 'Wrong username / password combination!';
            break;
          }
          default: {
            this.ErrorMessage = 'Server error. Contact support.';
            break;
          }
        }
      }
    }
  }
}
