








































/* eslint-disable object-curly-newline */
import 'reflect-metadata';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class DisplayBox extends Vue {
  @Prop({
    default: [],
  }) readonly filters!: Array<{
    field: string;
    operator: string;
    operand: string;
    month?: number;
  }>;

  get processedFilters() {
    return this.filters.filter((f) => {
      if (f.month && f.operator === 'lesser') return false;
      return true;
    }).map((f) => {
      let icon: string;
      console.log(f.field);
      switch (f.field) {
        case 'created_at':
          if (f.operator === 'greater') {
            icon = 'mdi-sort-calendar-ascending';
          } else {
            icon = 'mdi-sort-calendar-descending';
          }
          break;
        case 'location_id':
          icon = 'mdi-map-marker';
          break;
        case 'text':
          icon = 'mdi-text';
          break;
        case 'meta->user->name':
          icon = 'mdi-account-circle';
          break;
        case 'service_task.sth_id':
          icon = 'mdi-file-document-outline';
          break;
        default:
          icon = 'mdi-filter-variant';
      }
      return { ...f, icon };
    });
  }
}
