





























































































































































































import 'reflect-metadata';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Task, Service } from '@/types/pst-types';
import ServiceFormElement from '@/components/application/PackagesAndServices/ServiceFormElement.vue';
import PSTService from '@/services/PST.service';

@Component({
  components: { ServiceFormElement },
})
export default class NewService extends Vue {
  FormValid = false;

  Loading = false;

  ActiveLoading = false;

  ActiveService = new Service();

  ConsistencyKeys: number[] = [];

  /* TODO: Remove hard-coded entries */
  /* HARD-CODED SECTION - TO REDO AT FIRST REFACTORING SESSION */

  TaskTypeMap: Record<string, number> = {
    checkbox: 1,
    text: 2,
    file: 3,
    finance: 4,
  };

  async mounted() {
    const PSTS = new PSTService();
    const response = await PSTS.getService({ id: parseInt(this.$route.params.id, 10) });

    await response.data.template_tasks.forEach((task: Task) => {
      this.ActiveService.tasks.push(task);
    });

    this.ActiveService.name = response.data.name;

    this.ActiveService.sessionable = response.data.sessionable;

    this.ActiveLoading = false;
  }
}
