/* eslint-disable implicit-arrow-linebreak, camelcase */
import HttpClient from '@/services/Api.service';
import axios, { AxiosPromise, AxiosResponse } from 'axios';
import Store from '@/store';

interface CreatePacBody {
  name: string;
  services: number[];
}

interface CreateSerBody {
  name: string;
  sessionable: boolean;
  tasks: Array<{
    name: string;
    type_id: number;
    text: string;
    order: number;
  }>;
}

interface IdParams {
  id: number;
}

interface TaskSetBody {
  task_id: number;
  state: boolean | string | number | {
    value: string | boolean;
    payment_method: number;
  };
}

interface SplitTaskBody {
  task_id: number;
  new_values: number[];
}

interface DuplicateTaskBody {
  task_id: number;
  new_value?: number;
}

interface MakeCollaboratorBody {
  name: string,
  default_sum: number,
}

interface CollaboratorTransaction {
  collaborator_id: number,
  package_id: number,
  customer_id: number,
  amount: number,
  state: boolean,
  payment_method: number,
}

interface SingleCollabTrans {
  package_id: number,
  customer_id: number,
}

class PSTService extends HttpClient {
  public constructor() {
    super((process.env.VUE_APP_BASE_URL)!);
  }

  public createSinglePackage = (pac: CreatePacBody): AxiosPromise =>
    this.instance.post('/create-package', JSON.stringify(pac));

  public createSingeService = (ser: CreateSerBody): AxiosPromise =>
    this.instance.post('/create-service', JSON.stringify(ser));

  public getAllPackages = (): AxiosPromise =>
    this.instance.get('/get-all-packages');

  public getAllServices = (): AxiosPromise =>
    this.instance.get('/get-all-services');

  public getAllTaskTypes = (): AxiosPromise =>
    this.instance.get('/get-all-task-types');

  public getPackage = (params: IdParams): AxiosPromise =>
    this.instance.get('/get-package', { params });

  public getService = (params: IdParams): AxiosPromise =>
    this.instance.get('/get-service', { params });

  public setTaskState = (tsk: TaskSetBody): AxiosPromise =>
    this.instance.post('/set-task-state', tsk);

  public setTaskStateFile = (fd: FormData): AxiosPromise =>
    axios({
      method: 'post',
      url: `${process.env.VUE_APP_BASE_URL}/set-task-state`,
      data: fd,
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        Authorization: `Bearer ${Store.state.User.Token}`,
      },
    });

  public splitTask = (stb: SplitTaskBody): AxiosPromise =>
    this.instance.post('/split-task', JSON.stringify(stb));

  public duplicateTask = (stb: DuplicateTaskBody): AxiosPromise =>
    this.instance.post('/duplicate-task', JSON.stringify(stb));

  public getAllCollaborators = (): AxiosPromise =>
    this.instance.get('/get-all-collaborators');

  public makeCollaborator = (mkcol: MakeCollaboratorBody): AxiosPromise =>
    this.instance.post('/make-collaborator', JSON.stringify(mkcol));

  public executeCollaboratorTransaction = (colabtrans: CollaboratorTransaction): AxiosPromise =>
    this.instance.post('/execute-collaborator-transaction', JSON.stringify(colabtrans));

  public getSingleCollaboratorTransaction = (sct: SingleCollabTrans): AxiosPromise =>
    this.instance.post('/get-single-collaborator-transaction', JSON.stringify(sct));
  /**
   * TODO: To be implemented
   *
   *   public archivePackage;
   *
   *   public archiveService;
   *
   */
}

export default PSTService;
