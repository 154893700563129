












































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import CustomerService from '@/services/Customer.service';
import DocumentService from '@/services/Document.service';
import { Customer } from '@/types/customer-types';
import { CustomerIDocument, IDocumentType } from '@/types/document-types';
import UtilityBar from '@/components/application/UtilityBar/UtilityBar.vue';
import { AxiosResponse } from 'axios';
import PSCard from '@/components/application/PackagesAndServices/PSCard.vue';
import Loading from '@/components/application/Loading.vue';
import CustomerDocument from '@/components/application/CustomerProfile/CustomerDocument.vue';
import { Package } from '@/types/pst-types';
import { BarAction } from '@/components/application/UtilityBar/utility-bar-types';

@Component({
  components: {
    PSCard,
    Loading,
    CustomerDocument,
    UtilityBar,
  },
})
export default class CustomerPackageDashboard extends Vue {
  public barChunks: BarAction[] = [
    {
      icon: 'mdi-arrow-left',
      text: 'BACK TO PROFILE',
      eventName: 'back-to-profile',
    },
  ];

  EditMode = false;

  CustomerData = new Customer();

  DisplayData = new Array<Record<string, string>>();

  DocumentTypes = new Array<IDocumentType>();

  CreationDate = '';

  Loading = true;

  Avatar = 0;

  Documents = new Array<CustomerIDocument>();

  Packages: Package[] = [];

  CustomerPackages: Package[] = [];

  async goBackToProfile() {
    await this.$router.push({
      name: 'Customer Profile',
      params: {
        id: this.$route.params.id,
      },
    });
  }

  async mounted() {
    this.Packages = [];
    let CService = new CustomerService();

    const responses: any = await Promise.all([Package.getAll(), CService.getBelongingPackages(
      { id: parseInt(this.$route.params.id, 10) },
    )]);
    responses[0].data.forEach((pack: any) => {
      const tempPack = new Package();
      tempPack.fromRaw(pack);
      this.Packages.push(tempPack);
    });

    if (responses[1].data.packages_full.length > 0) {
      console.log(responses[1].data);
      this.CustomerPackages = responses[1].data.packages_full
        .map((packData: unknown) => {
          const p = new Package();
          p.fromRaw(packData);
          return p;
        });
    }

    this.Packages = this.Packages.filter(
      (pkg: Package) => !this.CustomerPackages.find((cpack: Package) => cpack.id === pkg.id),
    );

    CService = new CustomerService();
    let response: AxiosResponse;
    try {
      this.Loading = true;

      // TODO: Make these three requests in parallel, not in series.

      response = await CService.getSingle({ id: parseInt(this.$route.params.id, 10) });
      this.Avatar = response.data.id;
      this.CustomerData.fromRaw(response.data);
      this.CreationDate = response.data.created_at;

      this.DisplayData.push(
        {
          name: 'STATUS',
          data: this.CustomerData.status.toUpperCase(),
        },
        {
          name: 'FIRST NAME',
          data: this.CustomerData.first_name,
        },
        {
          name: 'LAST NAME',
          data: this.CustomerData.last_name,
        },
        {
          name: 'EMAIL ADDRESS',
          data: this.CustomerData.email,
        },
        {
          name: 'PHONE NUMBER',
          data: this.CustomerData.phone,
        },
        {
          name: 'LOCATION',
          data: this.CustomerData.location!.name,
        },
      );

      this.Loading = false;
    } catch (error) {
      console.log(error);
    }
  }

  async assignPackage(id: number) {
    const CService = new CustomerService();
    await CService.assignPackage({
      id: this.$route.params.id,
      // eslint-disable-next-line camelcase
      package_id: id,
    });
    this.$router.go(0);
  }

  async deletePackage(id: number) {
    const CS = new CustomerService();
    console.log('attempted');
    await CS.deletePackage({
      package_id: id,
      customer_id: parseInt(this.$route.params.id, 10),
    });
    this.$router.go(0);
  }
}
