









/* eslint-disable object-curly-newline */
import 'reflect-metadata';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class ToggleFilter extends Vue {
  @Prop({
    default: () => [],
  }) readonly onState!: Array<[string, string, string, string?]>;

  @Prop({
    default: () => [],
  }) readonly offState!: Array<[string, string, string, string?]>;

  public state = false;

  @Watch('state')
  onModelChanged(newVal: number | undefined): void {
    if (newVal) {
      if (this.offState.length > 0) {
        this.$emit('unset-filters', this.offState.map((state) => state[0]));
      }
      if (this.onState.length > 0) {
        this.$emit('set-filters', this.onState
          .filter((state) => state.length >= 3)
          .map((state) => ({
            field: state[0],
            operator: state[1],
            operand: state[2],
            display: state[3] || state[0],
          })));
      }
    } else {
      if (this.onState.length > 0) {
        this.$emit('unset-filters', this.onState.map((state) => state[0]));
      }
      if (this.offState.length > 0) {
        this.$emit('set-filters', this.offState
          .filter((state) => state.length >= 3)
          .map((state) => ({
            field: state[0],
            operator: state[1],
            operand: state[2],
            display: state[3] || state[0],
          })));
      }
    }
  }
}
