
































import 'reflect-metadata';
// eslint-disable-next-line object-curly-newline
import { Vue, Component, Prop } from 'vue-property-decorator';
import { PAYMENT, TRADER } from '@/types/transaction-enums';
import TransactionService from '@/services/Transaction.service';
import UserService from '@/services/User.service';

@Component
export default class DeleteTrasactionDialog extends Vue {
  @Prop() readonly model!: boolean;

  @Prop() readonly transactionId!: number;

  public guardedClose(): void {
    if (!this.loading) {
      this.$emit('click:outside');
    }
  }

  public loading = false;

  async deleteTransaction() {
    const TS = new TransactionService();
    await TS.deleteTransaction({ id: this.transactionId });
    this.guardedClose();
    this.$emit('deletion-done');
  }
}
