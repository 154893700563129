/* eslint-disable eqeqeq */
export default (toToggle: string | boolean): boolean => {
  if (toToggle === 'true') return false;
  if (toToggle === 'false') return true;
  /**
   * Here shallow equals is desirable as the toggle logic
   * is supposed to work on falsy-truthy values as well
   */
  if (toToggle == false) return true;
  if (toToggle == true) return false;
  return !toToggle;
};
