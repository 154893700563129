
















































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { hasPermissions } from '@/util/has-permissions.util';
import PSCard from '@/components/application/PackagesAndServices/PSCard.vue';
import Loading from '@/components/application/Loading.vue';
import { Package, Service } from '@/types/pst-types';

@Component({
  components: {
    PSCard,
    Loading,
  },
})
export default class PackagesDashboard extends Vue {
  Packages: Package[] = [];

  Services: Service[] = [];

  LoadingState = true;

  // eslint-disable-next-line class-methods-use-this
  public hasPermissions(...permissions: string[]) {
    return hasPermissions(...permissions);
  }

  public async goToNewService() {
    await this.$router.push({ name: 'New Service' });
  }

  public async goToNewPackage() {
    await this.$router.push({ name: 'New Package' });
  }

  public async goToService(id: number) {
    await this.$router.push({ name: 'View Service Template', params: { id: id.toString() } });
  }

  public async goToPackage(id: number) {
    await this.$router.push({ name: 'View Package Template', params: { id: id.toString() } });
  }

  async mounted() {
    this.Packages = [];
    const responses: any = await Promise.all([Package.getAll(), Service.getAll()]);
    responses[0].data.forEach((pack: any) => {
      const tempPack = new Package();
      tempPack.fromRaw(pack);
      this.Packages.push(tempPack);
    });
    responses[1].data.forEach((serv: any) => {
      const tempServ = new Service();
      tempServ.fromRaw(serv);
      this.Services.push(tempServ);
    });
    this.LoadingState = false;
  }
}

