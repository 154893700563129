



























































import 'reflect-metadata';
// eslint-disable-next-line object-curly-newline
import { Vue, Component, Prop } from 'vue-property-decorator';
import { PAYMENT, TRADER } from '@/types/transaction-enums';
import TransactionService from '@/services/Transaction.service';
import UserService from '@/services/User.service';

@Component
export default class AddMoneyDialog extends Vue {
  @Prop() readonly model!: boolean;

  @Prop() readonly availableAmount!: number;

  public guardedClose(): void {
    if (!this.loading) {
      this.$emit('click:outside');
    }
  }

  public loading = false;

  public transactionAmount = 0;

  public transactionNote = '';

  public async makeTransaction(): Promise<void> {
    if (this.transactionAmount > 0
      && !Number.isNaN(this.transactionAmount)
      && !this.loading) {
      this.loading = true;
      const TService = new TransactionService();
      const transactionBody: any = {
        sender: {
          type: TRADER.META,
          id: 2,
        },
        recipient: {
          type: TRADER.OFFICER,
          id: -1,
        },
        amount: this.transactionAmount,
        payment_method: PAYMENT.CASH,
        text: this.transactionNote || 'No note specified',
      };
      await TService.makeTransaction(transactionBody);
      this.loading = false;
      this.$emit('transaction-done');
      this.guardedClose();
      this.transactionAmount = 0;
      this.transactionNote = '';
    }
  }

  get conditions(): boolean {
    return (this.transactionAmount > 0
      && !Number.isNaN(this.transactionAmount));
  }
}
