interface CallbackData {
  message: string;
  [K: string]: any;
}

type EventCallback = (data: CallbackData) => void;

class ErrorBus {
  private _subscribers: Record<string, Array<EventCallback>> = {};

  public on(event: string, callback: EventCallback): void {
    if (typeof this._subscribers[event] === 'undefined') {
      this._subscribers[event] = new Array<EventCallback>();
    }

    this._subscribers[event].push(callback);
  }

  public emit(event: string, data: CallbackData): void {
    if (typeof this._subscribers === 'undefined') return;

    this._subscribers[event].forEach((callback: EventCallback) => {
      callback(data);
    });
  }
}

export default new ErrorBus();
