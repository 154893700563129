
























































































































/* eslint-disable import/no-cycle */
import Vue from 'vue';
import Component from 'vue-class-component';
import UserService from '@/services/User.service';
import { User } from '@/types/user-types';
import { CustomerIDocument, IDocumentType } from '@/types/document-types';
import { AxiosResponse } from 'axios';

@Component
export default class CustomerProfile extends Vue {
  UserData = new User();

  DisplayData = new Array<Record<string, string>>();

  Logs = new Array<Record<string, any>>();

  DocumentTypes = new Array<IDocumentType>();

  CreationDate = '';

  Loading = true;

  Avatar = 0;

  Documents = new Array<CustomerIDocument>();

  async mounted() {
    const UService = new UserService();
    let response: AxiosResponse;
    try {
      this.Loading = true;

      // TODO: Make these three requests in parallel, not in series.

      response = await UService.getSingle({ email: this.$store.state.User.Email });
      this.UserData.fromRaw(response.data);

      this.DisplayData.push(
        {
          name: 'STATUS',
          // TODO: This is hard-coded, remove this in the next release
          data: this.UserData.permission_level === 1 ? 'EMPLOYEE' : 'ADMINISTRATOR',
        },
        {
          name: 'NAME',
          data: this.UserData.name,
        },
        {
          name: 'EMAIL ADDRESS',
          data: this.UserData.email,
        },
        {
          name: 'LOCATION',
          data: this.UserData.location!.name,
        },
      );

      this.Loading = false;
    } catch (error) {
      console.log(error);
    }
  }
}
