














































































































































































































































































































































































































































































































































import {
  Vue, Component, Watch,
} from 'vue-property-decorator';
import AddMoneyDialog from '@/components/application/Transactions/AddMoneyDialog.vue';
import DateIntSelect from '@/components/application/Filters/DateIntSelect.vue';
import DisplayBox from '@/components/application/Filters/DisplayBox.vue';
import Loading from '@/components/application/Loading.vue';
import LocationService from '@/services/Location.service';
import SendMoneyDialog from '@/components/application/Transactions/SendMoneyDialog.vue';
import StringSearch from '@/components/application/Filters/StringSearch.vue';
import StringSelect from '@/components/application/Filters/StringSelect.vue';
import TransactionService from '@/services/Transaction.service';
import UserService from '@/services/User.service';
import UtilityBar from '@/components/application/UtilityBar/UtilityBar.vue';
import { AxiosResponse } from 'axios';
import { BarAction } from '@/components/application/UtilityBar/utility-bar-types';
import { Location } from '@/types/location-types';
import { TRADER } from '@/types/transaction-enums';
import InfoDisplayBox from '@/components/application/Filters/InfoDisplayBox.vue';
import DeleteTrasactionDialog from '@/components/application/Transactions/DeleteTrasactionDialog.vue';
import saveByteArray from '@/util/bytes-to-file.util';

@Component({
  components: {
    InfoDisplayBox,
    AddMoneyDialog,
    DateIntSelect,
    DisplayBox,
    Loading,
    SendMoneyDialog,
    StringSearch,
    StringSelect,
    UtilityBar,
    DeleteTrasactionDialog,
  },
})
export default class Index extends Vue {
  public barChunks: BarAction[] = [
    // {
    //   icon: 'mdi-send',
    //   text: 'SEND MONEY',
    //   eventName: 'send-money-start',
    // },
    // {
    //   icon: 'mdi-download-multiple',
    //   text: 'ADD MONEY',
    //   eventName: 'add-money-start',
    // },
    {
      icon: 'mdi-pail',
      text: 'BUCKETS',
      eventName: 'go-to-buckets',
    },
    {
      icon: 'mdi-table-arrow-down',
      text: 'DOWNLOAD EXCEL',
      eventName: 'download-excel',
    },
  ];

  public picker = (new Date(
    Date.now() - (new Date()
    ).getTimezoneOffset() * 60000,
  )).toISOString().substr(0, 10);

  @Watch('picker')
  public onPickerChange(newVal: string): void {
    this.setFilters([{
      field: 'created_at',
      operator: 'lesser',
      operand: newVal,
    }]);
  }

  public timeTravel = false;

  public async downloadExcel() {
    const TService = new TransactionService();

    let response: Blob;

    let paginateFunction: (params: any) => Promise<AxiosResponse> = TService.paginateAll;
    if (this.selectedOfficer !== null) {
      paginateFunction = TService.getWallet;
    }

    try {
      let params;
      if (this.selectedOfficer !== null) {
        params = {
          filters: this.Filters,
          order: this.SortOrder,
          wallet_id: this.selectedOfficer.id,
          wallet_type: TRADER.OFFICER,
          mode: this.currentMode,
          download_excel: true,
        };
      } else {
        params = {
          filters: this.Filters,
          order: this.SortOrder,
          mode: this.currentMode,
          download_excel: true,
        };
      }

      response = (await paginateFunction(params)) as unknown as Blob;
      saveByteArray(document, window)(response, 'finances.xlsx');
    } catch (e) {
      console.log(e);
    }
  }

  public OfficerVariantsFull: Array<{id: number, name: string}> = [];

  public selectedOfficer: {id: number, name: string} | null = null;

  @Watch('selectedOfficer')
  public onSelectedOfficerChange(): void {
    this.hardFilter = '';
  }

  public addMoneyDialog = false;

  public openAddMoney(): void {
    this.addMoneyDialog = true;
  }

  public closeAddMoney(): void {
    this.addMoneyDialog = false;
  }

  public sendMoneyDialog = false;

  public openSendMoney(): void {
    this.sendMoneyDialog = true;
  }

  public closeSendMoney(): void {
    this.sendMoneyDialog = false;
  }

  deleteTransactionId = -1;

  deleteTransactionDialog = false;

  public openDeleteTransaction(id: number): void {
    this.deleteTransactionId = id;
    this.markedId = id;
    this.deleteTransactionDialog = true;
  }

  markedId = -1;

  public closeDeleteTransaction(): void {
    this.markedId = -1;
    this.deleteTransactionDialog = false;
  }

  public async goToBuckets() {
    await this.$router.push({ name: 'Services & Buckets' });
  }

  SearchField = '';

  TotalPages = 0;

  LoadingResults = false;

  CurrentPage = 0;

  TableData: any[] = [];

  SortOrder = 'descending';

  @Watch('selectedOfficer')
  onOfficerChange(newVal: any) {
    if (newVal === null) {
      this.walletValue = 0;
      this.realWalletValue = 0;
    }
    this.paginate('');
  }

  public walletValue = 0;

  public realWalletValue = 0;

  public income = 0;

  public expense = 0;

  public profit = 0;

  public estimatedMode = false;

  public hardFilter = '';

  public setHardFilter(v: string, paginateAfter = true) {
    if (this.hardFilter === v) this.hardFilter = '';
    else this.hardFilter = v;
    if (paginateAfter) this.paginate('');
  }

  public mode = '';

  public get getMode(): 'estimated' | 'real' | '' {
    switch (this.mode) {
      case 'estimated':
        return 'estimated';
      case 'real':
        return 'real';
      default:
        return '';
    }
  }

  @Watch('estimatedMode')
  onEstimatedModeChange(newVal: boolean) {
    if (newVal) {
      this.incomeMode = false;
      this.outcomeMode = false;
    }
  }

  public incomeMode = false;

  @Watch('incomeMode')
  onIncomeModeChange(newVal: boolean) {
    if (newVal) {
      this.estimatedMode = false;
      this.outcomeMode = false;
    }
  }

  public outcomeMode = false;

  @Watch('outcomeMode')
  onOutcomeModeChange(newVal: boolean) {
    if (newVal) {
      this.incomeMode = false;
      this.estimatedMode = false;
    }
  }

  public get currentMode(): string {
    if (this.estimatedMode) return 'estimated';
    if (this.incomeMode) return 'income';
    if (this.outcomeMode) return 'outcome';
    return 'real';
  }

  public infoData: Array<{
    field: string;
    value: string | number;
    hardFilter?: string;
  }> = [];

  async paginate(e: string, page = '1') {
    const TService = new TransactionService();

    let response: AxiosResponse;

    let paginateFunction: (params: any) => Promise<AxiosResponse> = TService.paginateAll;
    if (this.selectedOfficer !== null) {
      paginateFunction = TService.getWallet;
    }

    this.TableData = [];
    try {
      this.LoadingResults = true;

      let params;
      if (this.selectedOfficer !== null) {
        params = {
          page: parseInt(page, 10),
          filters: this.Filters,
          order: this.SortOrder,
          wallet_id: this.selectedOfficer.id,
          wallet_type: TRADER.OFFICER,
          mode: this.getMode || undefined,
          hard_filter: this.hardFilter || undefined,
        };
      } else {
        params = {
          page: parseInt(page, 10),
          filters: this.Filters,
          order: this.SortOrder,
          mode: this.getMode || undefined,
          hard_filter: this.hardFilter || undefined,
        };
      }

      response = await paginateFunction(params);
      this.LoadingResults = false;
      const TransactionData = response.data.entries.data;
      this.TotalPages = response.data.entries.last_page;
      this.income = response.data.income;
      this.expense = response.data.expense;
      this.profit = response.data.profit;
      this.infoData = [
        { field: 'Cash Income', value: response.data.cashIncome, hardFilter: 'cash_income' },
        { field: 'Card Income', value: response.data.cardIncome, hardFilter: 'card_income' },
        { field: 'Total Income', value: response.data.income },
        { field: 'Outcome Petty Cash', value: response.data.petty, hardFilter: 'petty_cash' },
        { field: 'Real Outcome', value: response.data.realExpenses, hardFilter: 'outcome' },
        {
          field: 'Estimated + Real Outcome',
          value: response.data.estimatedExpenses,
          hardFilter: 'estimated_outcome',
        },
        {
          field: 'Internal Transactions',
          value: response.data.internal,
          hardFilter: 'internal',
        },
      ];
      this.CurrentPage = parseInt(page, 10);
      TransactionData.forEach((transaction: any) => {
        this.TableData.push(transaction);
      });
      if (this.selectedOfficer !== null) {
        this.walletValue = response.data.amount;
        this.realWalletValue = response.data.real_amount;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async refresh() {
    const TS = new TransactionService();
    await this.paginate('');
    const wallet = await TS.getWallet({
      filters: [],
      wallet_type: TRADER.OFFICER,
      wallet_id: -1,
    });
    this.personalWalletValue = wallet.data.amount;
  }

  // eslint-disable-next-line class-methods-use-this
  public parseDate(input: string): string {
    const d = new Date(input);
    return `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`;
  }

  Filters: Array<{
    display?: string;
    month?: number;
    field: string;
    operator: string;
    operand: string;
  }> = [];

  public setFilters(filters: Array<{
    field: string;
    operator: string;
    operand: string;
  }>): void {
    filters.forEach((filter) => {
      this.Filters = this.Filters.filter((e) => e.field !== filter.field);
    });
    console.log(filters);
    this.Filters = this.Filters.concat(filters);
    this.paginate('');
  }

  public unsetFilters(filters: string[]): void {
    filters.forEach((filter) => {
      this.Filters = this.Filters.filter((e) => e.field !== filter);
    });
    console.log(filters);
    this.paginate('');
  }

  LocationVariants: string[] = [];

  OfficerVariants: string[] = [];

  personalWalletValue = 0;

  async mounted() {
    const LS = new LocationService();
    const US = new UserService();
    const TS = new TransactionService();

    const fd = new Date();
    fd.setDate(1);
    // Last day
    const ld = new Date(fd.getTime());
    ld.setMonth(fd.getMonth() + 1);
    this.Filters = [
      {
        field: 'created_at',
        operator: 'greater',
        operand: `${fd.getFullYear()}-${fd.getMonth() + 1}-${fd.getDate()}`,
        month: fd.getMonth(),
        display: fd.toLocaleString('default', { month: 'long' }),
      },
      {
        field: 'created_at',
        operator: 'lesser',
        operand: `${ld.getFullYear()}-${ld.getMonth() + 1}-${ld.getDate()}`,
        month: fd.getMonth(),
      },
    ];

    const responses = await Promise.all([
      this.paginate(''),
      LS.getAll(),
      US.getAll(),
      TS.getWallet({
        wallet_id: -1,
        wallet_type: TRADER.OFFICER,
      }),
    ]);

    this.LocationVariants = responses[1].data.map((location: Location) => location);
    this.OfficerVariants = responses[2].data.map((officer: any) => officer.name);
    this.OfficerVariantsFull = responses[2].data.map((officer: any) => ({
      name: officer.name,
      id: officer.id,
    }));
    this.personalWalletValue = responses[3].data.amount;
  }
}
