import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Store from '@/store/index';
import Login from '@/views/Login.vue';
import ApplicationWrapper from '@/views/dashboard/Index.vue';
import OldCustomerIndex from '@/views/application/CustomerIndex.vue';
import CustomerProfile from '@/views/application/CustomerProfile.vue';
import NewCustomer from '@/views/application/CreateMenu/NewCustomer.vue';
import NewLead from '@/views/application/CreateMenu/NewLead.vue';
import CreateMenu from '@/views/application/CreateMenu/CreateMenu.vue';
import NewLocation from '@/views/application/CreateMenu/NewLocation.vue';
import NewEmployee from '@/views/application/CreateMenu/NewEmployee.vue';
import UserProfile from '@/views/application/UserProfile.vue';
import ManageMenu from '@/views/application/ManageMenu/ManageMenu.vue';
import UserList from '@/views/application/ManageMenu/UserList.vue';
import EditLead from '@/views/application/EditMenu/EditLead.vue';
import UpgradeLead from '@/views/application/EditMenu/UpgradeLead.vue';
import EditCustomer from '@/views/application/EditMenu/EditCustomer.vue';
import CustomerJourney from '@/views/application/CustomerJourney.vue';
import LocationList from '@/views/application/ManageMenu/LocationList.vue';
import PackagesDashboard from '@/views/application/PackagesAndServices/Dashboard.vue';
import NewService from '@/views/application/PackagesAndServices/NewService.vue';
import NewPackage from '@/views/application/PackagesAndServices/NewPackage.vue';
import ServicePage from '@/views/application/PackagesAndServices/ServicePage.vue';
import PackagePage from '@/views/application/PackagesAndServices/PackagePage.vue';
import CustomerPackage from '@/views/application/Customer/CustomerPackage.vue';
import SessionDashboard from '@/views/application/Sessions/SessionDashboard.vue';
import CustomerPackageDashboard from '@/views/application/Customer/CustomerPackageDashboard.vue';
import ErrorComponent from '@/views/application/Error.vue';
import axios, { AxiosResponse } from 'axios';
import SingleSession from '@/views/application/Sessions/SingleSession.vue';
import CustomerDetails from '@/views/application/Customer/CustomerDetails.vue';
import CustomerIndex from '@/views/application/CustomerPanel/Index.vue';
import TransactionIndex from '@/views/application/Transactions/Index.vue';
import TransactionBuckets from '@/views/application/Transactions/Buckets/Index.vue';
import FinancesIndex from '@/views/application/Finances/Index.vue';
import Audit from '@/views/application/Audit/Index.vue';

Vue.use(VueRouter);

async function getRole(): Promise<AxiosResponse> {
  return axios({
    method: 'get',
    url: `${process.env.VUE_APP_BASE_URL}/get-user-role`,
    params: {
      email: Store.state.User.Email,
    },
    headers: {
      Authorization: `Bearer ${Store.state.User.Token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
}

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    /**
     * The UserIsLoggedIn state is cleared on session exit, as such,
     * it's value signifies whether the auth token validity was checked
     * for the current session.
     *
     * To validate the statement, a simple /get-user-role request is performed.
     * This achieves two things, setting the updated user permissions in case of
     * an update to the database, and checking whether the current token still
     * qualifies the logged in user for authenticated requests.
     *
     * If not, then the session variable UserIsLoggedIn is not set to true, and
     * the candidate user is taken to the login page. If the request is ok,
     * that means that the token is still valid and that the user can proceed
     * to the current session.
     */
    beforeEnter: async (to, from, next) => {
      if (Store.state.User.UserIsLoggedIn) {
        next({ name: 'Application' });
      } else {
        try {
          const response: AxiosResponse = await getRole();
          const { permissions } = JSON.parse(response.data.data.permissions);
          Store.commit('User/setPermissions', permissions);
          Store.commit('User/setUserIsLoggedIn', true);
          next({ name: 'Application' });
        } catch (e) {
          next();
        }
      }
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter: (to, from, next) => {
      Store.dispatch('User/logout');
      next({ name: 'Login' });
    },
  },
  {
    path: '/application',
    name: 'Application',
    component: ApplicationWrapper,
    redirect: '/application/customer-index',
    /**
     * The logic is the same as the one described for the /login route above.
     */
    beforeEnter: async (to, from, next) => {
      if (to.name !== 'Login' && !Store.state.User.UserIsLoggedIn) {
        try {
          const response: AxiosResponse = await getRole();
          const { permissions } = JSON.parse(response.data.data.permissions);
          Store.commit('User/setPermissions', permissions);
          Store.commit('User/setUserIsLoggedIn', true);
          next();
        } catch (e) {
          Store.dispatch('User/logout');
          next({ name: 'Login' });
        }
      } else next();
    },
    children: [
      {
        path: '/application/old-customer-index',
        name: 'Old Customer Index',
        component: OldCustomerIndex,
      },
      {
        path: '/application/customer/:id',
        name: 'Customer Profile',
        component: CustomerProfile,
        meta: {
          transition: 'slide-right',
        },
      },
      {
        path: '/application/customer/journey/:id',
        name: 'Customer Journey',
        component: CustomerJourney,
        meta: {
          transition: 'slide-left',
        },
      },
      {
        path: '/application/create-menu',
        name: 'Create',
        component: CreateMenu,
      },
      {
        path: '/application/new-customer',
        name: 'New Customer',
        component: NewCustomer,
      },
      {
        path: '/application/new-lead',
        name: 'New Lead',
        component: NewLead,
      },
      {
        path: '/application/new-location',
        name: 'New Location',
        component: NewLocation,
      },
      {
        path: '/application/new-user',
        name: 'New Officer',
        component: NewEmployee,
      },
      {
        path: '/application/user-profile',
        name: 'User Profile',
        component: UserProfile,
      },
      {
        path: '/application/manage-menu',
        name: 'Manage',
        component: ManageMenu,
      },
      {
        path: '/application/user-list',
        name: 'Employee List',
        component: UserList,
      },
      {
        path: '/application/edit-lead',
        name: 'Edit Lead',
        component: EditLead,
        meta: {
          transition: 'slide-left',
        },
      },
      {
        path: '/application/upgrade-lead',
        name: 'Upgrade Lead to Customer',
        component: UpgradeLead,
        meta: {
          transition: 'slide-left',
        },
      },
      {
        path: '/application/edit-customer/:id',
        name: 'Edit Customer',
        component: EditCustomer,
        meta: {
          transition: 'slide-left',
        },
      },
      {
        path: '/application/location-list',
        name: 'Offices',
        component: LocationList,
      },
      {
        path: '/application/packages',
        name: 'Packages & Services',
        component: PackagesDashboard,
      },
      {
        path: '/application/new-service',
        name: 'New Service',
        component: NewService,
      },
      {
        path: '/application/new-package',
        name: 'New Package',
        component: NewPackage,
      },
      {
        path: '/application/view-service-template/:id',
        name: 'View Service Template',
        component: ServicePage,
      },
      {
        path: '/application/view-package-template/:id',
        name: 'View Package Template',
        component: PackagePage,
      },
      {
        path: '/application/customer/:id/package/:package_id',
        name: 'Customer\'s Package Status',
        component: CustomerPackage,
      },
      {
        path: '/application/sessions',
        name: 'Session Dashboard',
        component: SessionDashboard,
      },
      {
        path: '/application/session/:date',
        name: 'View Single Session',
        component: SingleSession,
      },
      {
        path: '/application/transactions',
        name: 'View Transactions',
        component: TransactionIndex,
      },
      {
        path: '/application/transactions/services',
        name: 'Services & Buckets',
        component: TransactionBuckets,
      },
      {
        path: '/application/customer/:id/packages',
        name: 'Customer Packages',
        component: CustomerPackageDashboard,
      },
      {
        path: '/application/customer-details/:id',
        name: 'Customer Details',
        component: CustomerDetails,
        meta: {
          transition: 'slide-right',
        },
      },
      {
        path: '/application/customer-index',
        name: 'Customer Index',
        component: CustomerIndex,
      },
      {
        path: '/application/finances',
        name: 'Finances',
        component: FinancesIndex,
      },
      {
        path: '/application/audit',
        name: 'Audit',
        component: Audit,
      },
    ],
  },
  {
    path: '*',
    redirect: '/login',
  },
  {
    path: '/error',
    name: 'Error',
    component: ErrorComponent,
    props: true,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
