





































import 'reflect-metadata';
// eslint-disable-next-line object-curly-newline
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class ExistingSessionsModal extends Vue {
  @Prop() readonly model!: boolean;

  @Prop() readonly dates!: string[];
}
