/* eslint-disable object-curly-newline */
/* eslint-disable @typescript-eslint/no-empty-interface */
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import Store from '@/store/index';
import ErrorBus from '@/util/error-bus.util';
// import router from '@/router/index';

abstract class HttpClient {
  protected readonly instance: AxiosInstance;

  protected constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL,
      headers: {
        Authorization: `Bearer ${Store.state.User.Token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    this._initializeResponseInterceptor();
  }

  private _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      this._handleResponse,
      this._handleError,
    );
  };

  private _handleResponse = ({ data }: AxiosResponse) => data;

  protected _handleError = async (error: any): Promise<any> => {
    const data: {message: string; [K: string]: any;} = {
      message: 'No message provided',
    };
    if (typeof error.response !== 'undefined') {
      console.log(error.response);
      data.message = `${error.response.data.message}`;
    }

    if (error) {
      ErrorBus.emit('snackbar-error', data);
    }

    return Promise.reject(error);
  }
}

export default HttpClient;
