/* eslint-disable camelcase */
import PSTService from '@/services/PST.service';
import { AxiosPromise } from 'axios';
import { MissingFieldsError } from '@/util/missing-fields-error.util';
import { assignIfDefined } from '@/util/assign-if-defined.util';
import { stripFunctions } from '@/util/strip-functions.util';

/**
 * This file contains all of the types related to
 * Packages
 * Services
 * Tasks
 */

export interface TaskType {
  id: number;
  /* TODO: This shouldn't be an empty string */
  name: 'checkbox' | 'text' | 'finance' | 'file' | '';
}

export class Task {
  public customer_id?: number;

  public finance: number | undefined = undefined;

  public id = -1;

  public name = '';

  public text = '';

  public order = -1;

  public package_id?: number;

  public sth_id = -1;

  public type: TaskType | undefined = undefined;

  public optional = false;

  public value?: string;

  public sessionable = false;

  public get completed(): boolean {
    if (this.optional) {
      return true;
    }
    if (typeof this.type === 'undefined' || typeof this.value === 'undefined') {
      return false;
    }
    switch (this.type.name) {
      case 'checkbox':
        return this.value === 'true';
      case 'text':
        return this.value !== '';
      case 'finance':
        if (typeof this.finance === 'undefined') {
          return false;
        }
        return this.value === 'true';
      case 'file':
        return this.value !== '';
      default:
        break;
    }
    return false;
  }

  public get fileCompleted(): boolean {
    if (this.type?.name !== 'file') return false;
    return this.value !== '';
  }

  public fromRaw = (rt: any): void => {
    const assignIfAvailable = assignIfDefined.bind(this);
    assignIfAvailable<number>('customer_id', rt.customer_id);
    assignIfAvailable<number>('finance', rt.finance);
    assignIfAvailable<number>('id', rt.id);
    assignIfAvailable<string>('name', rt.name);
    assignIfAvailable<string>('text', rt.text);
    assignIfAvailable<number>('order', rt.order);
    assignIfAvailable<number>('package_id', rt.package_id);
    assignIfAvailable<number>('sth_id', rt.sth_id);
    assignIfAvailable<TaskType>('type', rt.type);
    assignIfAvailable<boolean>('sessionable', rt.sessionable);
    assignIfAvailable<boolean>('optional', rt.optional);
    if (typeof this.type === 'undefined') {
      if (typeof rt.type_id !== 'undefined') {
        this.type = {
          id: rt.type_id,
          name: '',
        };
      }
    }
    assignIfAvailable<string>('value', rt.value);
  }
}

export class Service {
  public id = -1;

  public name = '';

  public status: 'active' | 'archived' | '' = '';

  public tasks: Task[] = [];

  public sessionable = false;

  public fromRaw = (rs: any): void => {
    const assignIfAvailable = assignIfDefined.bind(this);
    assignIfAvailable<number>('id', rs.id);
    assignIfAvailable<string>('name', rs.name);
    assignIfAvailable<'active' | 'archived' | ''>('status', rs.status);
    assignIfAvailable<boolean>('sessionable', rs.sessionable);
    let rawTasks: any = rs.tasks;
    if (rs.template_tasks) {
      rawTasks = rs.template_tasks;
    }
    if (rawTasks) {
      const convertedTasks: Task[] = rawTasks.map((task: any) => (new Task()).fromRaw(task));
      assignIfAvailable<Task[]>('tasks', convertedTasks);
    }
  }

  public save = (): AxiosPromise => {
    if (!(this.name
        && this.status
        && this.tasks.length > 0)) {
      throw new MissingFieldsError('Some or all required fields for user creation are not set.');
    }

    const rawService: any = stripFunctions(this);

    if (this.id === -1) {
      delete rawService.id;
    }

    return (new PSTService()).createSingeService(rawService);
  }

  public static getAll = (): AxiosPromise => (new PSTService()).getAllServices();
}

export class Package {
  public id = -1;

  public name = '';

  public status: 'active' | 'archived' | '' = '';

  public services: Array<Service | number> = [];

  public fromRaw = (rp: any): void => {
    const assignIfAvailable = assignIfDefined.bind(this);
    assignIfAvailable<number>('id', rp.id);
    assignIfAvailable<string>('name', rp.name);
    assignIfAvailable<'active' | 'archived' | ''>('status', rp.status);
    let rawServs: any = rp.services;
    if (rp.service_templates) {
      rawServs = rp.service_templates;
    }
    if (rawServs) {
      const convertedServices: Service[] = [];
      rawServs.forEach((service: any) => {
        const tempServ = new Service();
        tempServ.fromRaw(service);
        convertedServices.push(tempServ);
      });
      assignIfAvailable<Service[]>('services', convertedServices);
    }
  }

  public save = (): AxiosPromise => {
    if (!(this.name
        && this.status
        && this.services.length > 0)) {
      throw new MissingFieldsError('Some or all required fields for user creation are not set.');
    }

    const rawPackage: any = stripFunctions(this);

    if (this.id === -1) {
      delete rawPackage.id;
    }

    return (new PSTService()).createSinglePackage(rawPackage);
  }

  public static getAll = (): AxiosPromise => (new PSTService()).getAllPackages();
}
