






















import { Vue, Component, Prop } from 'vue-property-decorator';
import 'reflect-metadata';
import ErrorBus from '@/util/error-bus.util';

interface SnackbarError {
  message: string;
  code?: number;
}

@Component
export default class ErrorSnack extends Vue {
  public model = false;

  public message = 'No message provided';

  public code = -1;

  mounted(): void {
    ErrorBus.on('snackbar-error', (data: SnackbarError) => {
      this.message = data.message;
      this.code = data.code ? data.code : -1;
      this.model = true;
    });
  }
}
