


























































/* eslint-disable object-curly-newline */
import 'reflect-metadata';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class DateIntSelect extends Vue {
  @Prop({
    default: 'N/A',
  }) readonly field!: string;

  @Prop({
    default: '',
  }) readonly name!: string;

  @Prop({
    default: 'date',
  }) readonly type!: string;

  public dataModel: string[] = [];

  public singleDataModel: string | null = '';

  @Watch('singleDataModel')
  onSingleModelChanged(newVal: string | null): void {
    if (newVal === null || newVal === '') {
      this.$emit('unset-filters', [this.field]);
      return;
    }
    const fd = new Date(newVal);
    // Last day
    const ld = new Date(fd.getTime());
    ld.setMonth(fd.getMonth() + 1);
    if (this.type === 'month') {
      this.$emit('set-filters', [
        {
          field: this.field,
          operator: 'greater',
          operand: `${fd.getFullYear()}-${fd.getMonth() + 1}-${fd.getDate()}`,
          month: fd.getMonth(),
          display: fd.toLocaleString('default', { month: 'long' }),
        },
        {
          field: this.field,
          operator: 'lesser',
          operand: `${ld.getFullYear()}-${ld.getMonth() + 1}-${ld.getDate()}`,
          month: fd.getMonth(),
        },
      ]);
    }
  }

  @Watch('dataModel')
  onModelChanged(newVal: string[]): void {
    if (newVal.length === 0) {
      this.$emit('unset-filters', [this.field]);
    } else if (newVal.length === 1 || (newVal.length === 2 && newVal[0] === newVal[1])) {
      const nd = new Date(newVal[0]);
      nd.setDate(nd.getDate() + 1);
      this.$emit('set-filters', [
        {
          field: this.field,
          operator: 'greater',
          operand: newVal[0],
          display: newVal[0],
        },
        {
          field: this.field,
          operator: 'lesser',
          operand: `${nd.getFullYear()}-${nd.getMonth() + 1}-${nd.getDate()}`,
          display: `${nd.getFullYear()}-${nd.getMonth() + 1}-${nd.getDate()}`,
        },
      ]);
    } else if (newVal.length === 2) {
      const emitValues: string[] = [...newVal];

      emitValues.sort((a: string, b: string) =>
        (new Date(a)).getTime() - (new Date(b)).getTime());

      const nd = new Date(emitValues[1]);
      nd.setDate(nd.getDate() + 1);
      emitValues[1] = `${nd.getFullYear()}-${nd.getMonth() + 1}-${nd.getDate()}`;

      if (this.type === 'date') {
        this.$emit('set-filters', [
          {
            field: this.field,
            operator: 'greater',
            operand: emitValues[0],
            display: emitValues[0],
          },
          {
            field: this.field,
            operator: 'lesser',
            operand: emitValues[1],
            display: emitValues[1],
          },
        ]);
      }
    }
  }
}
