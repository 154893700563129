



























































































































































































































































































































/* eslint-disable import/no-duplicates */
import Vue from 'vue';
import Component from 'vue-class-component';
import CustomerService from '@/services/Customer.service';
import DocumentService from '@/services/Document.service';
import { Customer, CustomerBuilder } from '@/types/customer-types';
import { CustomerIDocument, IDocumentType } from '@/types/document-types';
import { AxiosResponse } from 'axios';
import { PhoneNumber, CountryCode } from 'libphonenumber-js';
import parsePhoneNumber from 'libphonenumber-js';

type VuetifyDoctypeSelect<T> = {
  [P in keyof T]: T[P];
} & {
  text: string;
}

@Component
export default class UpgradeLead extends Vue {
  ID!: number;

  LocalCustomer = new Customer();

  LocalDocuments = new Array<CustomerIDocument>();

  Builder = new CustomerBuilder(this.LocalCustomer);

  DocumentTypes = new Array<IDocumentType>();

  SelectDocTypes = new Array<VuetifyDoctypeSelect<IDocumentType>>();

  Loading = true;

  RequestLoading = false;

  FormValid = false;

  PhoneCountry: CountryCode = 'GB';

  PhoneCountries: CountryCode[] = this.$store.state.General.phoneCountries;

  LocalPhoneNumber(): PhoneNumber | undefined {
    return parsePhoneNumber(
      this.LocalCustomer.phone,
      this.PhoneCountry,
    );
  }

  ErrorDocuments: Array<Record<string, string>> = [];

  CheckDocumentForError(index: string): boolean {
    let found = false;

    for (let i = 0; i < this.ErrorDocuments.length; i += 1) {
      if (index === this.ErrorDocuments[i].index) {
        found = true;
        break;
      }
    }

    if (found) {
      this.FormConditions.UniqueDocs = false;
      this.OneTimeWatchers.DocumentUnwatch[parseInt(index, 10)] = this.$watch(
        () => `${this.LocalDocuments[parseInt(index, 10)].type_id}
                       ${this.LocalDocuments[parseInt(index, 10)].code}`,
        () => {
          this.ErrorDocuments = this.ErrorDocuments.filter(
            (value): boolean => (value.index !== index),
          );
          if (this.ErrorDocuments.length === 0) this.FormConditions.UniqueDocs = true;
          this.OneTimeWatchers.DocumentUnwatch[parseInt(index, 10)]();
        },
      );
    }

    return found;
  }

  ValidationRules: Record<string, Array<(v: string) => boolean | string>> = {
    FirstNameRules: [
      (v: string): boolean | string => !!v || 'First name required!',
    ],
    LastNameRules: [
      (v: string): boolean | string => !!v || 'Last name required!',
    ],
    EmailRules: [
      (v: string): boolean | string => !v || /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    PhoneRules: [],
  };

  async mounted() {
    const customerData: Customer = JSON.parse(decodeURIComponent(this.$route.params.data));
    this.Builder.Status('customer')
      .FirstName(customerData.first_name)
      .LastName(customerData.last_name)
      .Email(customerData.email)
      .Phone(customerData.phone);
    this.ID = customerData.id!;
    this.LocalCustomer.id = customerData.id;

    const ds = new DocumentService();

    try {
      this.Loading = true;
      const response: AxiosResponse = await ds.getAllTypes();
      response.data.forEach((doctype: any) => {
        this.DocumentTypes.push(new IDocumentType(doctype.id, doctype.name, doctype.description));
      });
      this.Loading = false;
    } catch (error) {
      console.log(error);
    }
  }

  FormConditions = {
    UniqueEmail: true,
    UniquePhone: true,
    UniqueDocs: true,
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  OneTimeWatchers: Record<string, Function[]> = {
    EmailUnwatch: [() => null],
    PhoneUnwatch: [() => null],
    DocumentUnwatch: [],
  };

  async upgradeLead() {
    const cs = new CustomerService();

    try {
      this.RequestLoading = true;
      const ProcessedCustomer = this.LocalCustomer;
      ProcessedCustomer.phone = this.LocalPhoneNumber()!.number as string;

      const reqJSON = {
        type: ProcessedCustomer.status,
        customer: ProcessedCustomer,
        documents: new Array<any>(),
      };

      const FD = new FormData();

      if (this.LocalDocuments.length !== 0) {
        this.LocalDocuments.forEach((doc) => {
          reqJSON.documents.push(doc.toRaw([
            'type_id',
            'code',
          ]));
          FD.append('uploaded_files[]', doc.file);
        });
      }

      FD.append('json_data', JSON.stringify(reqJSON));

      await cs.upgradeSingle(FD);
      this.RequestLoading = false;
      /* TODO: Either pass additional params or signal on
               this page that the creation was succssful */
      this.$router.push({ name: 'Customer Index' });
    } catch (error) {
      this.RequestLoading = false;
      this.FormValid = false;
      if ('errors' in error.response.data) {
        const { errors } = error.response.data;
        if ('customer.email' in errors) {
          this.FormConditions.UniqueEmail = false;
          this.OneTimeWatchers.EmailUnwatch[0] = this.$watch(
            () => this.LocalCustomer.email,
            () => {
              this.FormConditions.UniqueEmail = true;
              this.OneTimeWatchers.EmailUnwatch[0]();
            },
          );
        }

        if ('customer.phone' in errors) {
          this.FormConditions.UniquePhone = false;
          this.OneTimeWatchers.PhoneUnwatch[0] = this.$watch(
            () => this.LocalCustomer.phone,
            () => {
              this.FormConditions.UniquePhone = true;
              this.OneTimeWatchers.PhoneUnwatch[0]();
            },
          );
        }
      }

      if (error.response.data.data.email_duplicate) {
        this.FormConditions.UniqueEmail = false;
        this.OneTimeWatchers.EmailUnwatch[0] = this.$watch(
          () => this.LocalCustomer.email,
          () => {
            this.FormConditions.UniqueEmail = true;
            this.OneTimeWatchers.EmailUnwatch[0]();
          },
        );
      }

      if (error.response.data.data.phone_duplicate) {
        this.FormConditions.UniquePhone = false;
        this.OneTimeWatchers.PhoneUnwatch[0] = this.$watch(
          () => this.LocalCustomer.phone,
          () => {
            this.FormConditions.UniquePhone = true;
            this.OneTimeWatchers.PhoneUnwatch[0]();
          },
        );
      }

      if ('data' in error.response.data) {
        const { data: errors } = error.response.data;
        errors.forEach((element: Record<string, string>): void => {
          this.ErrorDocuments.push(element);
        });
      }
    }
  }

  appendDocument(): void {
    this.LocalDocuments.push(new CustomerIDocument());
  }

  removeDocument(index: number): void {
    if (typeof this.OneTimeWatchers.DocumentUnwatch[index] === 'function') {
      this.OneTimeWatchers.DocumentUnwatch[index]();
    }
    this.LocalDocuments.splice(index, 1);
    this.ErrorDocuments = this.ErrorDocuments.filter(
      (value): boolean => (value.index !== `${index}`),
    );
    if (this.ErrorDocuments.length === 0) this.FormConditions.UniqueDocs = true;
  }

  get UniqueEmailError() {
    if (this.FormConditions.UniqueEmail) return [];
    return 'Email must be unique!';
  }

  get UniquePhoneError() {
    const PN: boolean = (this.LocalCustomer.phone && this.LocalCustomer.phone !== '0')
      ? this.LocalPhoneNumber()!.isValid() : false;
    if (PN
      && this.FormConditions.UniquePhone) {
      return [];
    }
    if (!this.FormConditions.UniquePhone) {
      return 'Phone must be unique!';
    }
    return 'The given phone number is invalid';
  }
}
