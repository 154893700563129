







































































































































































































/* eslint-disable camelcase */
import Vue from 'vue';
import Component from 'vue-class-component';
import { User } from '@/types/user-types';
import { Location } from '@/types/location-types';
import UserService from '@/services/User.service';
import LocationService from '@/services/Location.service';
import { AxiosResponse } from 'axios';

@Component
export default class NewEmployee extends Vue {
  LocalUser: User = new User();

  LocationList = new Array<Location>();

  /* TODO: This is hard-coded. Implement a request based call for permissions */
  PermissionList: Array<Record<string, string | number>> = [
    {
      id: 1,
      name: 'Officer',
    },
    {
      id: 2,
      name: 'Administrator',
    },
  ];

  RequestLoading = false;

  FormValid = false;

  Loading = true;

  FormConditions = {
    UniqueEmail: true,
  }

  ValidationRules: Record<string, Array<(v: string) => boolean | string>> = {
    NameRules: [
      (v: string): boolean | string => !!v || 'First name required!',
    ],
    EmailRules: [
      (v: string): boolean | string => !!v || 'Last name required!',
      (v: string): boolean | string => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    PasswordRules: [
      (v: string): boolean | string => !!v || 'Password address required!',
      (v: string): boolean | string => v.length > 5 || 'Password must be at leas 5 characters!',
    ],
    PhoneRules: [
      (v: string): boolean | string => !!v || 'Phone number required!',
    ],
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  OneTimeWatchers: Record<string, Function> = {
    EmailUnwatch: () => null,
  };

  async mounted() {
    const LService = new LocationService();

    this.LocalUser.fromRaw({
      name: '',
      email: '',
      password: '',
      location_id: 0,
      permission_level: 0,
    });

    try {
      this.Loading = true;
      const response: AxiosResponse = await LService.getAll();
      response.data.forEach((location: Location) => {
        this.LocationList.push(location);
      });
      this.Loading = false;
    } catch (error) {
      console.log(error);
    }
  }

  async createEmployee() {
    const UService = new UserService();

    try {
      this.RequestLoading = true;
      await this.LocalUser.save();
      this.RequestLoading = false;
      /* TODO: Either pass additional params or signal on
               this page that the creation was successful */
      this.$router.push({ name: 'Customer Index' });
    } catch (error) {
      console.log(error);
      this.RequestLoading = false;
      this.FormValid = false;
      const { errors } = error.response.data;
      if ('user.email' in errors) {
        this.FormConditions.UniqueEmail = false;
        this.OneTimeWatchers.EmailUnwatch = this.$watch(
          () => this.LocalUser.email,
          () => {
            this.FormConditions.UniqueEmail = true;
            this.OneTimeWatchers.EmailUnwatch();
          },
        );
      }
    }
  }

  get UniqueEmailError() {
    if (this.FormConditions.UniqueEmail) return [];
    return 'Email must be unique!';
  }
}
