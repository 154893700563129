import HttpClient from '@/services/Api.service';
import { CustomerIDocument } from '@/types/document-types';
import axios from 'axios';
import Store from '@/store';

/* eslint-disable camelcase */
interface DocumentParameters {
  id: number;
}
/* eslint-enable camelcase */

class DocumentService extends HttpClient {
  public constructor() {
    super((process.env.VUE_APP_BASE_URL)!);
  }

  public getSingle = (params: DocumentParameters) => this.instance.get('/get-document', { params });

  public makeSingle = (d: CustomerIDocument) => this.instance.post('/create-document', JSON.stringify(d));

  public editSingle = (patch: string, params: DocumentParameters) => this.instance.patch('/edit-document', patch, { params });

  public archiveSingle = (params: DocumentParameters) => this.instance.delete('/archive-document', { params });

  public getOwner = (params: DocumentParameters) => this.instance.get('/get-document-owner', { params });

  public getAllTypes = () => this.instance.get('/get-all-document-types');

  public updateDocument = (FD: FormData) => axios({
    method: 'post',
    url: `${process.env.VUE_APP_BASE_URL}/update-document`,
    data: FD,
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      Authorization: `Bearer ${Store.state.User.Token}`,
    },
  });
}
export default DocumentService;
