













































import copyToClipboard from '@/util/copy-to-clipboard.util';
import { Vue, Component, Prop } from 'vue-property-decorator';
import 'reflect-metadata';
import { AxiosError } from 'axios';

const defaultError: any = {
  response: {
    status: -1,
    data: {
      message: 'This is not an error. Navigate back.',
    },
  },
};

@Component
export default class Error extends Vue {
  @Prop({ required: true, default: defaultError }) readonly error!: AxiosError;

  mounted() {
    if (this.error?.response?.status === -1) {
      this.$router.push({ name: 'Login' });
    }
  }

  public copy(): void {
    if (typeof this.error !== 'undefined') {
      copyToClipboard(document, JSON.stringify(this.error.response));
    }
  }
}
