import Vue from 'vue';
import vueDebounce from 'vue-debounce';
import Hotjar from 'vue-hotjar';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

import vuetify from './plugins/vuetify';
import i18n from './i18n';
import './plugins/base';
import './plugins/chartist';
import './plugins/vee-validate';

import './sass/overrides.sass';
import './sass/variables.scss';
import './sass/fonts.sass';
import './sass/transitions.sass';
import 'vuesax/dist/vuesax.css';

Vue.config.productionTip = false;

Vue.use(vueDebounce);

// Vue.use(Hotjar, {
//   id: 2240767,
//   isProduction: process.env.NODE_ENV === 'production',
// });

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || to.name;
  });
});

export default new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
