





















import 'reflect-metadata';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Loading extends Vue {
  @Prop() readonly loading!: boolean;

  @Prop({ default: '90vh' }) readonly height!: string;
}
