/* eslint-disable implicit-arrow-linebreak, prefer-destructuring */
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import HttpClient from '@/services/Api.service';
import Store from '@/store/index';
import FileType from 'file-type/browser';

type FileModes = 'download' | 'view';

export default class FileService extends HttpClient {
  private static readonly _fileAccessURI = `${process.env.VUE_APP_BASE_URL}/get-file`;

  // eslint-disable-next-line max-len
  public static async AccessFile(filepath: string, _window: any, _document: any, mode: FileModes = 'download') {
    const response = await axios.get(this._fileAccessURI, {
      headers: {
        Authorization: `Bearer ${Store.state.User.Token}`,
        'Content-Type': 'application/json',
      },
      params: mode === 'download' ? { filepath } : { filepath, mode: 'inline' },
      responseType: 'arraybuffer',
    });

    let blob: Blob;
    const mime = await FileType.fromBuffer(response.data);
    if (mode === 'view' && typeof mime !== 'undefined') {
      blob = new Blob([response.data], { type: mime.mime });
    } else {
      blob = new Blob([response.data]);
    }
    let filename = 'file';

    if (mode !== 'view') {
      try {
        filename = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
        filename = filename.slice(1, -1);
      } catch (e) {
        console.log(e);
      }
    }

    const url: string = _window.URL.createObjectURL(blob);
    const link = _document.createElement('a');
    link.href = url;

    if (mode === 'view') {
      link.setAttribute('target', '_blank');
      _window.open(url);
    } else {
      link.setAttribute('download', filename);
      _document.body.appendChild(link);
      link.click();
    }
  }
}
