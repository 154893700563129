






































































































import 'reflect-metadata';
// eslint-disable-next-line object-curly-newline
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import CustomerService from '@/services/Customer.service';
import PSTService from '@/services/PST.service';
import SessionService from '@/services/Session.service';
import saveByteArray from '@/util/bytes-to-file.util';
import { arrayBufferToBlob } from 'blob-util';
import ErrorBus from '@/util/error-bus.util';

@Component
export default class MassDownloadDialog extends Vue {
  @Prop() readonly model!: boolean;

  @Prop() readonly date!: Date;

  @Prop() readonly serviceId!: number;

  Data: Record<string, unknown>[] = []

  selectAll() {
    this.Data.forEach((datum: any) => {
      datum.files.forEach((file: any) => {
        if (file.value !== '') {
          // eslint-disable-next-line no-param-reassign
          file.selected = true;
        }
      });
    });
  }

  @Watch('serviceId')
  async onServiceIdChanged(newId: number) {
    this.loading = true;
    const SS = new SessionService();
    const d = this.date;
    const response = await SS.getAvailableFiles({
      session_date: `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`,
      sth_id: this.serviceId,
    });
    this.Data = response.data;
    this.Data.forEach((datum: any) => {
      datum.files.forEach((file: any) => {
        // eslint-disable-next-line no-param-reassign
        this.$set(file, 'selected', false);
      });
    });
    this.loading = false;
    console.log(response);
  }

  async attemptDownload() {
    this.ButtonLoading = true;
    const requestOptions = this.Data.filter((datum: any) =>
      typeof (datum.files.find((f: any) => f.selected)) !== 'undefined')
      .map((datum: any) => ({
        customer_id: datum.customer.id,
        sth_id: datum.sth_id,
        files: datum.files.filter((file: any) => file.selected).map((file: any) => (
          {
            name: file.value,
            task_name: file.name,
          }
        )),
      }));
    if (requestOptions.length === 0) {
      ErrorBus.emit('snackbar-error', {
        message: 'Can\'t request an empty archive!',
      });
      this.ButtonLoading = false;
      return;
    }
    const response = await (new SessionService()).massDownload(
      { file_data: requestOptions },
    ) as unknown as Blob;
    // console.log(response);
    // eslint-disable-next-line
    // const binary = base64StringToBlob(base64.replace(/\s/g, ''), 'application/zip');
    saveByteArray(document, window)(response, 'document.zip');
    this.ButtonLoading = false;
  }

  public guardedClose(): void {
    if (!this.loading && !this.ButtonLoading) {
      this.$emit('click:outside');
    }
  }

  public loading = false;

  public ButtonLoading = false;
}
