import { render, staticRenderFns } from "./CustomerProfile.vue?vue&type=template&id=5bc58c3e&scoped=true&"
import script from "./CustomerProfile.vue?vue&type=script&lang=ts&"
export * from "./CustomerProfile.vue?vue&type=script&lang=ts&"
import style0 from "./CustomerProfile.vue?vue&type=style&index=0&id=5bc58c3e&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bc58c3e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCard,VCardSubtitle,VCardTitle,VCol,VContainer,VDivider,VPagination,VProgressCircular,VRow,VSimpleTable,VSkeletonLoader})
