import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import User from '@/store/modules/User';
import Theme from '@/store/modules/Theme';
import General from '@/store/modules/General';

/**
 * TODO:
 *  As the application expands, it may be a better idea
 *  to use vuex-persist, since it is more configurable
 *  and flexible.
 */

Vue.use(Vuex);
const store = new Vuex.Store({
  strict: true,
  plugins: [
    createPersistedState({
      paths: ['User.Email', 'User.Token', 'User.ID'],
    }),
  ],
  modules: {
    User,
    Theme,
    General,
  },
});
export default store;
