/* eslint-disable object-curly-newline */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';

@Module({ namespaced: true })
class Theme extends VuexModule {
  public barColor = 'rgba(0,0,0, .8), rgba(0, 0, 0, .8)';

  public barImage = 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg';

  public drawer: any = null;

  @Mutation
  public SET_DRAWER(payload: any) {
    this.drawer = payload;
  }
}

export default Theme;
