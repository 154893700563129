



















































import 'reflect-metadata';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { BarAction } from '@/components/application/UtilityBar/utility-bar-types';

@Component
export default class UtilityBar extends Vue {
  @Prop() readonly chunks!: BarAction[];
}
