







import Vue from 'vue';
import ErrorSnack from '@/components/application/ErrorSnack.vue';

export default Vue.extend({
  name: 'App',

  components: {
    ErrorSnack,
  },

  data: () => ({
    //
  }),
});
