/* eslint-disable import/prefer-default-export */
/**
 * @function assignIfDefined
 * Utility method, for constructing instances from
 * unpredictable input data. To be used with Function.prototype.bind().
 */
export function assignIfDefined<T>(this: any, fieldName: string, assignable: T): void {
  /*
  * Warning: This condition can be tricky, since at times you may want
  * to pass down zeroes or empty strings, which are in fact valid and
  * defined values. Other times, you may not want to do that.
  *
  * TODO: Consider adding a special flag to assignIfDefined
  */
  if ((typeof assignable === 'number' && assignable === 0)
   || (typeof assignable === 'string' && assignable === '')
   || (typeof assignable === 'boolean' && assignable === false)
   || assignable) {
    this[fieldName] = JSON.parse(JSON.stringify(assignable));
  }
}
