




























































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { hasPermissions } from '@/util/has-permissions.util';
import PSCard from '@/components/application/PackagesAndServices/PSCard.vue';
import Loading from '@/components/application/Loading.vue';
import { Package, Service } from '@/types/pst-types';

@Component({
  components: {
    PSCard,
    Loading,
  },
})
export default class PackagesDashboard extends Vue {
  RequestLoading = false;

  IncludedServices: Service[] = [];

  AvailableServices: Service[] = [];

  FormPackage = new Package();

  LoadingState = true;

  // eslint-disable-next-line class-methods-use-this
  public hasPermissions(...permissions: string[]) {
    return hasPermissions(...permissions);
  }

  public async goToNewService() {
    await this.$router.push({ name: 'New Service' });
  }

  public async goToNewPackage() {
    await this.$router.push({ name: 'New Package' });
  }

  removeService(index: number): void {
    this.AvailableServices.push(this.IncludedServices[index]);
    this.IncludedServices.splice(index, 1);
  }

  addService(index: number): void {
    this.IncludedServices.push(this.AvailableServices[index]);
    this.AvailableServices.splice(index, 1);
  }

  async createPackage() {
    this.IncludedServices.forEach((sv: Service) => {
      this.FormPackage.services.push(sv.id);
    });
    this.FormPackage.status = 'active';
    try {
      this.RequestLoading = true;
      await this.FormPackage.save();
      this.RequestLoading = false;
      await this.$router.push({ name: 'Packages & Services' });
    } catch (e) {
      console.log(e);
    }
  }

  async mounted() {
    const responses: any = await Promise.all([Service.getAll()]);
    responses[0].data.forEach((serv: any) => {
      const tempServ = new Service();
      tempServ.fromRaw(serv);
      this.AvailableServices.push(tempServ);
    });
    this.LoadingState = false;
  }

  ValidationRules: Record<string, Array<(v: string) => boolean | string>> = {
    RequiredRules: [
      (v: string): boolean | string => !!v || 'First name required!',
    ],
    EmailRules: [
      (v: string): boolean | string => !!v || 'Last name required!',
      (v: string): boolean | string => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    PasswordRules: [
      (v: string): boolean | string => !!v || 'Password address required!',
      (v: string): boolean | string => v.length > 5 || 'Password must be at leas 5 characters!',
    ],
    PhoneRules: [
      (v: string): boolean | string => !!v || 'Phone number required!',
    ],
  };
}

