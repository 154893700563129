









































































































































































































































































































































































































































































































































































































































































/* eslint-disable no-case-declarations, import/no-cycle */
import CustomerService from '@/services/Customer.service';
import FileService from '@/services/File.service';
import Loading from '@/components/application/Loading.vue';
import PSTService from '@/services/PST.service';
import SessionService from '@/services/Session.service';
import SplitTaskDialog from '@/components/application/PackagesAndServices/SplitTaskDialog.vue';
import ExistingSessionsModal from '@/components/application/Customer/ExistingSessionsModal.vue';
import UtilityBar from '@/components/application/UtilityBar/UtilityBar.vue';
import groupBy from '@/util/group-by';
import { AxiosPromise } from 'axios';
import { Task, Service, TaskType } from '@/types/pst-types';
import { Vue, Component } from 'vue-property-decorator';
import { assignIfDefined } from '@/util/assign-if-defined.util';
import { Customer } from '@/types/customer-types';
import { BarAction } from '@/components/application/UtilityBar/utility-bar-types';
import toggle from '@/util/toggle.util';
import Collaborator from '@/types/collaborator';
import DuplicateTaskDialog from '@/components/application/PackagesAndServices/DuplicateTaskDialog.vue';

class ContextualTask extends Task {
  public fromRaw = (rct: any): void => {
    const assignIfAvailable = assignIfDefined.bind(this);
    assignIfAvailable<number>('customer_id', rct.customer_id);
    assignIfAvailable<number>('finance', rct.finance);
    assignIfAvailable<number>('id', rct.id);
    assignIfAvailable<string>('name', rct.name);
    assignIfAvailable<string>('text', rct.text);
    assignIfAvailable<number>('order', rct.order);
    assignIfAvailable<number>('package_id', rct.package_id);
    assignIfAvailable<number>('sth_id', rct.sth_id);
    assignIfAvailable<boolean>('optional', rct.optional);
    assignIfAvailable<TaskType>('type', rct.type);
    assignIfAvailable<any>('sessions', rct.sessions);
    if (typeof this.type === 'undefined') {
      if (typeof rct.type_id !== 'undefined') {
        this.type = {
          id: rct.type_id,
          name: '',
        };
      }
    }
    assignIfAvailable<string>('meta', rct.meta);
    assignIfAvailable<string>('databaseMeta', rct.meta);
    assignIfAvailable<string>('value', rct.value);
    assignIfAvailable<string>('databaseValue', rct.value);
    assignIfAvailable<boolean>('sessionable', rct.sessionable);
  }

  public unhide = false;

  public meta: any = {};

  public databaseMeta: any = {};

  get financialCondition(): boolean {
    return (this.meta.payment_method === this.databaseMeta.payment_method
      && this.value === this.databaseValue)
      || ((this.value !== 'true' && this.value !== 'false')
      || typeof this.meta?.payment_method === 'undefined');
  }

  /**
   * @any fileValue
   * Used exclusively for file-type tasks, as an
   * auxiliary field for doing operations which
   * depend on form file data.
   */
  public fileValue: any = '';

  /**
   * @boolean loading
   * Denote whether the currently
   * database-submitted task action is awaiting a response
   * from the server.
   */
  public loading = false;

  /**
   * @boolean confirmed
   * Indicates whether the current value is in
   * concordance with the database. If the field is false,
   * it means that the current value of the task is
   * a pending change.
   */
  get confirmed(): boolean {
    if (this?.type?.name === 'finance') {
      return this.value === this.databaseValue
        && this.meta.payment_method === this.databaseMeta.payment_method;
    }
    return this.value === this.databaseValue;
  }

  /**
   * @string databaseValue
   * The latest assumed database value, based on
   * request history. This might become inaccurate
   * if changes from multiple clients occur.
   */
  public databaseValue = '';

  public async submit() {
    if (typeof this.type === 'undefined') {
      throw new Error('Expected a valid type for submission.');
    }

    if (typeof this.value === 'undefined') {
      throw new Error('Expected a valid value for submission.');
    }

    const PSTS = new PSTService();
    const FD = new FormData();
    let response;
    this.loading = true;

    switch (this.type.name) {
      case 'checkbox':
        response = await PSTS.setTaskState({
          task_id: this.id,
          state: `${this.value !== 'true'}`,
        });
        this.value = `${response.data.value}`;
        this.databaseValue = `${response.data.value}`;
        break;

      case 'text':
        response = await PSTS.setTaskState({
          task_id: this.id,
          state: `${this.value}`,
        });
        this.value = `${response.data.value}`;
        this.databaseValue = `${response.data.value}`;
        break;

      case 'finance':
        response = await PSTS.setTaskState({
          task_id: this.id,
          state: {
            value: this.value === 'true',
            payment_method: parseInt(this.meta.payment_method, 10),
          },
        });
        this.value = response.data.value;
        this.databaseValue = response.data.value;
        this.meta.payment_method = response.data.meta.payment_method;
        this.databaseMeta.payment_method = response.data.meta.payment_method;
        break;

      case 'file':
        if (!this.fileValue) {
          throw new Error('No file selected.');
        }

        FD.append('task_id', `${this.id}`);
        FD.append('state', this.fileValue);

        response = await PSTS.setTaskStateFile(FD);

        /**
         * NOTE: The double data field here
         * occurs due to the setTaskStateFile variant
         * of setTaskState, which uses axios directly, instead
         * of involving the class instance. Check PST.service.ts for
         * more info.
         */
        this.value = response.data.data.value;
        this.databaseValue = response.data.data.value;
        break;

      default:
        break;
    }

    this.loading = false;
  }
}

@Component({
  components: {
    Loading,
    SplitTaskDialog,
    ExistingSessionsModal,
    UtilityBar,
    DuplicateTaskDialog,
  },
})
export default class CustomerPackage extends Vue {
  /**
   * Collaborator section
   */
  public collaborator = new Collaborator();

  // eslint-disable-next-line class-methods-use-this
  public toggle(value: string | boolean): boolean {
    return toggle(value);
  }

  public barChunks: BarAction[] = [
    {
      icon: 'mdi-arrow-left',
      text: 'BACK TO PACKS',
      eventName: 'back-to-packages',
    },
  ];

  async goBackToPackages() {
    await this.$router.push({
      name: 'Customer Packages',
      params: {
        id: this.$route.params.id,
      },
    });
  }

  public PackageData = new Array<Record<string, string>>();

  public CustomerData = new Customer();

  public DisplayData = new Array<Record<string, string>>();

  public Tasks: ContextualTask[] = [];

  public Services: any[] = [];

  public ModelServices: Service[] = [];

  public ServiceNames: string[] = [];

  public LoadingState = true;

  public date: any[] = [];

  public menu: boolean[] = [];

  public duplicateModal = false;

  public duplicateModalFullValue = 0;

  public duplicateModalTaskId = 0;

  public splitModal = false;

  public splitModalFullValue = 0;

  public splitModalTaskId = 0;

  public metasForFinance: any[] = [
    {
      name: 'CASH',
      payment_method: 1,
    },
    {
      name: 'CARD',
      payment_method: 2,
    },
  ];

  public allCollaborators: any[] = [];

  /**
   * @boolean dialog
   * This represents the toggle value for the date-picker
   */
  public dialog = false;

  public datePicker = new Date().toISOString().substr(0, 10);

  public existingModal = false;

  public emDates: string[] = [];

  public openExistingSessions(index: number): void {
    this.emDates = this.Services[index][0].sessions.map((session: any) => session.date);
    this.existingModal = true;
  }

  public closeExistingSessions(): void {
    this.existingModal = false;
    this.emDates = [];
  }

  /**
   * TODO:
   * HARD-CODED MAP, REMOVE AFTER REIMPLEMENTATION
   */

  TaskTypeMap: Record<number, 'checkbox' | 'text' | 'finance' | 'file' | ''> = {
    1: 'checkbox',
    2: 'text',
    3: 'file',
    4: 'finance',
  };

  public async assignToSession(sth: number) {
    const ss = new SessionService();
    const dates = this.datePicker.split('-');
    await ss.assignServiceToSession({
      sth_id: sth,
      customer_id: parseInt(this.$route.params.id, 10),
      package_id: parseInt(this.$route.params.package_id, 10),
      day: parseInt(dates[2], 10),
      month: parseInt(dates[1], 10),
      year: parseInt(dates[0], 10),
    });
    this.$router.push({
      name: 'View Single Session',
      params: {
        date: `${parseInt(dates[0], 10)}-${parseInt(dates[1], 10)}-${parseInt(dates[2], 10)}`,
      },
    });
  }

  public completedNumber(index: number): number {
    let count = 0;
    for (let i = 0; i < this.Services[index].length; i += 1) {
      if (this.Services[index][i].completed
          && this.Services[index][i].confirmed) {
        count += 1;
      }
    }
    return count;
  }

  public openDuplicateModal(id: number, finance: number): void {
    this.duplicateModalFullValue = finance;
    this.duplicateModalTaskId = id;
    this.duplicateModal = true;
  }

  public openSplitModal(id: number, finance: string): void {
    this.splitModalFullValue = parseFloat(finance);
    this.splitModalTaskId = id;
    this.splitModal = true;
  }

  public closeSplitModal(): void {
    this.splitModal = false;
  }

  public closeDuplicateModal(): void {
    this.duplicateModal = false;
  }

  // eslint-disable-next-line class-methods-use-this
  public async downloadFile(filepath: string) {
    await FileService.AccessFile(filepath, window, document);
  }

  // eslint-disable-next-line class-methods-use-this
  public plusMinusColor(finance: number): string {
    if (Math.sign(finance) === -1) return 'red';
    return 'green';
  }

  async mounted() {
    const CService = new CustomerService();
    const PSTS = new PSTService();

    /* Defining task getting promise */
    const getTasksPromise: AxiosPromise = CService.getSingleBelongingPackage({
      id: this.$route.params.id,
      package_id: this.$route.params.package_id,
    });

    const getPackageNamePromise: AxiosPromise = PSTS.getPackage({
      id: parseInt(this.$route.params.package_id, 10),
    });

    const getCustomerDetails: AxiosPromise = CService.getSingle(
      { id: parseInt(this.$route.params.id, 10) },
    );

    /* Simultaneously firing promises */
    const responses: any[] = await Promise.all([
      Service.getAll(),
      getTasksPromise,
      getCustomerDetails,
      getPackageNamePromise,
      CService.getDetails({
        customer_id: parseInt(this.$route.params.id, 10),
      }),
      PSTS.getSingleCollaboratorTransaction({
        customer_id: parseInt(this.$route.params.id, 10),
        package_id: parseInt(this.$route.params.package_id, 10),
      }),
      PSTS.getAllCollaborators(),
    ]);

    if (JSON.stringify(responses[5].data) === '{}') {
      this.collaborator.fromRaw({
        amount: 0,
        state: false,
        payment_method: 1,
        collaborator: {
          id: -1,
        },
      });
    } else {
      this.collaborator.fromRaw(responses[5].data);
    }

    this.allCollaborators = responses[6].data;

    this.PackageData.push(
      {
        name: 'NAME',
        data: responses[3].data.name,
      },
    );

    this.CustomerData.fromRaw(responses[2].data);

    this.DisplayData.push(
      {
        name: 'STATUS',
        data: this.CustomerData.status.toUpperCase(),
      },
      {
        name: 'FIRST NAME',
        data: this.CustomerData.first_name,
      },
      {
        name: 'LAST NAME',
        data: this.CustomerData.last_name,
      },
      {
        name: 'EMAIL ADDRESS',
        data: this.CustomerData.email,
      },
      {
        name: 'PHONE NUMBER',
        data: this.CustomerData.phone,
      },
      {
        name: 'LOCATION',
        data: this.CustomerData.location!.name,
      },
    );

    responses[4].data.forEach((detail: any) => {
      this.DisplayData.push({
        name: detail.name || 'N/A',
        data: detail.value,
      });
    });

    /* Assigning all existing services to a model, since the customer tasks only contain the id */
    responses[0].data.forEach((serv: any) => {
      const tempServ = new Service();
      tempServ.fromRaw(serv);
      this.ModelServices.push(tempServ);
    });

    const rawTasks: any[] = responses[1].data;

    rawTasks.forEach((task: any) => {
      const newTask = new ContextualTask();
      newTask.fromRaw(task);
      newTask.type!.name = this.TaskTypeMap[newTask.type!.id];
      this.Tasks.push(newTask);
    });

    this.Services = groupBy('sth_id')(this.Tasks);

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in this.Services) {
      this.menu.push(false);
      this.date.push(null);
      if ({}.hasOwnProperty.call(this.Services, key)) {
        // eslint-disable-next-line eqeqeq
        this.ServiceNames[key] = this.ModelServices!.find((e) => `${e.id}` == key)!.name;
      }
    }

    this.LoadingState = false;
  }
}
