/* eslint-disable object-curly-newline */
import { VuexModule, Module } from 'vuex-module-decorators';
import { CountryCode } from 'libphonenumber-js';

@Module({ namespaced: true })
class General extends VuexModule {
  public phoneCountries: CountryCode[] = ['GB', 'RO', 'ES', 'PL', 'MD', 'IT'];
}

export default General;
