import { render, staticRenderFns } from "./PSCard.vue?vue&type=template&id=aab8f568&scoped=true&"
import script from "./PSCard.vue?vue&type=script&lang=ts&"
export * from "./PSCard.vue?vue&type=script&lang=ts&"
import style0 from "./PSCard.vue?vue&type=style&index=0&id=aab8f568&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aab8f568",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCard,VIcon})
