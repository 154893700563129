
















































































































import Vue from 'vue';
import Component from 'vue-class-component';
import LocationService from '@/services/Location.service';
import { Location } from '@/types/location-types';

@Component
export default class NewLead extends Vue {
  LocalLocation: Location = {
    name: '',
    address: '',
  };

  RequestLoading = false;

  FormValid = false;

  FormConditions = {
    UniqueName: true,
  }

  ValidationRules: Record<string, Array<(v: string) => boolean | string>> = {
    NameRules: [
      (v: string): boolean | string => !!v || 'Name required!',
    ],
    AddressRules: [
      (v: string): boolean | string => !!v || 'Address required!',
    ],
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  OneTimeWatchers: Record<string, Function> = {
    NameUnwatch: () => null,
  };

  async createLocation() {
    const LService = new LocationService();

    try {
      this.RequestLoading = true;
      await LService.makeSingle(this.LocalLocation);
      this.RequestLoading = false;
      /* TODO: Either pass additional params or signal on
               this page that the creation was successful */
      this.$router.push({ name: 'Customer Index' });
    } catch (error) {
      this.RequestLoading = false;
      this.FormValid = false;
      const { errors } = error.response.data;
      if ('name' in errors) {
        this.FormConditions.UniqueName = false;
        this.OneTimeWatchers.NameUnwatch = this.$watch(
          () => this.LocalLocation.name,
          () => {
            this.FormConditions.UniqueName = true;
            this.OneTimeWatchers.NameUnwatch();
          },
        );
      }
    }
  }

  get UniqueNameError() {
    if (this.FormConditions.UniqueName) return [];
    return 'Email must be unique!';
  }
}
