// eslint-disable-next-line import/prefer-default-export,no-shadow
export enum TRADER {
  META,
  OFFICER,
  CUSTOMER,
  COLLABORATOR,
  BUCKET,
}

// eslint-disable-next-line no-shadow
export enum PAYMENT {
  META,
  CASH,
  CARD,
}
