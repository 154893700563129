import HttpClient from '@/services/Api.service';
import { Location } from '@/types/location-types';

class LocationService extends HttpClient {
  public constructor() {
    super((process.env.VUE_APP_BASE_URL)!);
  }

  public makeSingle = (l: Location) => this.instance.post('/create-location', JSON.stringify(l));

  public getAll = () => this.instance.get('/get-all-locations');
}

export default LocationService;
