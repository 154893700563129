













































import 'reflect-metadata';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class PSCard extends Vue {
  @Prop() readonly type!: 'service' | 'package' | 'add-button' | 'empty';

  @Prop() readonly id!: number;

  @Prop() readonly name!: string;

  @Prop() readonly serviceCount: number | undefined;
}
